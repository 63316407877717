import ExchangeFormContext from "./ExchangeFormContext";
import { useState } from "react";

const ExchangeFormProvider = ({ children }) => {

  const [formValues, setFormValues] = useState();

  const onClear = () => setFormValues(undefined);

  return (
    <ExchangeFormContext.Provider value={{
      formValues,
      onSave: setFormValues,
      onClear,
    }}>
      {children}
    </ExchangeFormContext.Provider>
  )
};

export default ExchangeFormProvider;
