import styled from "styled-components";
import Popup from "reactjs-popup";
import { ClipBorder } from "../../../../GlobalStyled";

const StyledSelectPopup = styled(Popup)`
  &-content {
    margin: 0 auto!important;
    background: transparent;
    border: none;
    width: var(--container-width);
    height: fit-content;
  }
`;

const RootSelect = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;

const Dropdown = styled(ClipBorder)`
  position: absolute;
  top: 110%;
  width: 100%;
  z-index: 10;

  &:before {
    background: var(--accent-color);
    clip-path: polygon(0 20.00px,20.00px 0,100% 0,100% 100%,0 100%,0 20.00px,2px  calc(20.00px + 0.83px),2px calc(100% - 2px),calc(100% - 2px) calc(100% - 2px),calc(100% - 2px) 2px,calc(20.00px + 0.83px) 2px,2px calc(20.00px + 0.83px));
  }
  
  li {
    cursor: pointer;
    border-bottom: 2px solid transparent;
  }
  
  ul {
    max-height: 275px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px var(--bg-color--accent-color);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--accent-color);
    }
  }
`;

const BackgroundSelect = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  right: 0;
  z-index: -1;

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    background: var(--bg-color);
    clip-path: polygon(0 20.00px,20.00px 0,100% 0,100% 100%,0 100%);
  }
`;


const ArrowDrop = styled.div`
  transform: rotate(${({active}) => active ? '180deg' : 0});
  transition: transform .3s ease-out;
  justify-self: flex-end;
`;

const SelectValue = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 12px;
  position: relative;
  align-items: center;
  cursor: pointer;
  color: var(--text-color);
  transition: all .3s ease-out;
  padding: 20px;
  width: 100%;
  
  &:hover {
    color: var(--accent-color);
  }
`;

const IconWrap = styled.div`
  width: 32px;
  height: 32px;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
`;

export {
  StyledSelectPopup,
  SelectValue,
  ArrowDrop,
  RootSelect,
  Dropdown,
  BackgroundSelect,
  IconWrap,
};
