import {
  CbContainer,
  CbText,
  Mark,
} from "./styled";
import StyledWrapper from "./styled";

const Checkbox = ({ caption, checked, onChange, error, name }) => {

  return (
    <StyledWrapper name={name} onClick={onChange}>
      <CbContainer checked={checked} error={error}>
        <Mark/>
      </CbContainer>
      <CbText error={error}>{caption}</CbText>
      <input type="checkbox" hidden/>
    </StyledWrapper>
  );
};

export default Checkbox;
