export const formatNumber = (number, maximumFractionDigits) => {
    if(typeof number == 'string' && number.includes('.')) {
        var output = number.split('.');
        number = output.shift() + '.' + output.join('');
    }

    let asnumber = Number(number) ? number : +number.toString().replaceAll(/[^\d.]/g, '');
    const afterDot = asnumber % 1;
    const beforeDot = Math.floor(asnumber);

    const afterDotFormated = Intl.NumberFormat(undefined, {
        maximumSignificantDigits: maximumFractionDigits || 1
    }).format(afterDot).replace(',', '.')

    if(typeof number == 'string' && (number.endsWith('.') || (number.endsWith('0') && number.includes('.')))) {
        return number;
    }

    const res = Intl.NumberFormat(undefined, {
        maximumFractionDigits: maximumFractionDigits
    }).format((beforeDot + (+afterDotFormated))).replace(',', '.')

    return res.toString();
}