import Control, {
  ButtonWrap,
  Field,
  FieldRight,
  Inner
} from "./styled";

const BorderedControls = ({ left, right, button, copyButton, error, onClickButton}) => {

  return (
    <Control error={error}>
      <Inner stayFlex={Boolean(button)}>
        <Field>{left}</Field>
        {right && <FieldRight error={error}>{right}</FieldRight> }
        {button && <ButtonWrap type="button" onClick={onClickButton}>{button}</ButtonWrap>}
        {copyButton && <ButtonWrap type="button" onClick={onClickButton}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_841_94)">
                    <path d="M18 8H10C8.89543 8 8 8.89543 8 10V18C8 19.1046 8.89543 20 10 20H18C19.1046 20 20 19.1046 20 18V10C20 8.89543 19.1046 8 18 8Z" stroke="#1B8FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M16 8V6C16 5.46957 15.7893 4.96086 15.4142 4.58579C15.0391 4.21071 14.5304 4 14 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V14C4 14.5304 4.21071 15.0391 4.58579 15.4142C4.96086 15.7893 5.46957 16 6 16H8" stroke="#1B8FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_841_94">
                        <rect width="24" height="24" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </ButtonWrap>}
      </Inner>
    </Control>
  )
};

export default BorderedControls;
