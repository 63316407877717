import {
  BackgroundSelect,
  Dropdown,
  RootSelect
} from "./styled";
import Option from "./Option";
import {
  useEffect,
  useState
} from "react";

const ROOT_CLASS = 'dd-exist';

const Select = ({ options, selected, onSelect }) => {
  const [open, setOpen] = useState(false);

  const onOpen = () => {
    if (document.body.classList.contains(ROOT_CLASS)) {
      return;
    }

    setOpen(true);
  };
  const onClose = () => setOpen(false);

  const onRootClick = (event) => event.stopPropagation();

  useEffect(() => {
    if (open) {
      document.body.classList.add(ROOT_CLASS);
      window.addEventListener('click', onClose);
    } else {
      document.body.classList.remove(ROOT_CLASS);
      window.removeEventListener('click', onClose);
    }

    return () => {
      document.body.classList.remove(ROOT_CLASS);
      window.removeEventListener('click', onClose);
    };
  }, [open]);

  const onChange = (id) => () => {
    onSelect(id);
    onClose();
  };

  return (
    <RootSelect onClick={onRootClick}>
      <Option
        icon={selected ? selected.icon : null}
        name={selected ? selected.name : 'Нет доступной пары'}
        withControl={true}
        onClick={onOpen}
      />
      {open && (
        <Dropdown>
          <BackgroundSelect/>
          <ul>
            {options.map((item) => (
              <li key={item.id}>
                <Option
                  name={item.name}
                  icon={item.icon}
                  onClick={onChange(item)}
                />
              </li>
            ))}
          </ul>
        </Dropdown>
      )}
    </RootSelect>
  )
};

export default Select;
