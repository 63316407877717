import {
  CardInner,
  CbCaption,
  FieldWrap,
  Input,
  SubmitButton,
  Warning,
  HelpText,
  SwapButton
} from "../../styled";
import Field from "../Field/Field";
import BorderedControls from "../BorderedControls/BorderedControls";
import Checkbox from "../Checkbox/Checkbox";
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import Select from "../Select/Select";
import MaskedInput from 'react-text-mask';
import { useFormik } from 'formik';
import Spinner from "../../../../components/Spinner/Spinner";
import { useRequest } from "../../../../hooks/useRequest";
import { availableOperators, initExchange } from "../../../../api/request";
import { BackgroundLogo, FadeIn } from "../../../../GlobalStyled";
import { NavLink } from "react-router-dom";
import { useExchangeFormContext } from "../../../../providers/ExchangeFormContext";
import { formatNumber } from "../../../../utils/format";

import Modal from "../../../../components/Modal/Modal";

const FirstStep = ({ tokens, pairs, onNext }) => {

  const [tokenIn, setTokenIn] = useState();
  const [tokenOut, setTokenOut] = useState();
  const { formValues, onSave } = useExchangeFormContext();
  const [isSwapped, setIsSwapped] = useState(false);

  const { call, loading, error } = useRequest(initExchange);
  const {
    call: callOperators,
    loading: loadingOperators,
    error: errorOperators
  } = useRequest(availableOperators);
  const refInitComplete = useRef(false);
  const refPanInput = useRef();

  const [message, setMessage] = useState(null);

  const inTokensList = useMemo(() => {
    if (!tokenOut) {
      return [];
    }

    return tokens.filter((token) => pairs.some((pair) => pair.token_in === token.id) && token.is_crypto === isSwapped)
  }, [tokens, tokenOut, pairs, isSwapped]);

  useEffect(() => {
    setTokenIn(tokenOut || undefined)
    setTokenOut(tokenIn || undefined)
    
    const newIn = (+formik.values.amountOut).toFixed(0);

    const pair = pairs.find((pair) => pair.token_in === tokenOut.id && pair.token_out === tokenIn.id);
    if(pair) {
      formik.setFieldValue('amountIn', newIn);
      onChangeInAmount(newIn, pair);
    }
  }, [isSwapped])

  const outTokensList = useMemo(() => {
    if (!tokenIn) {
      return [];
    }

    return pairs
      .filter((pair) => pair.token_in === tokenIn.id)
      .map((pair) => tokens.find((item) => item.id === pair.token_out))
  }, [tokens, tokenIn, pairs]);

  useEffect(() => {
    if(tokenIn && tokenOut && !pairs.some((pair) => pair.token_in === tokenIn.id && pair.token_out === tokenOut.id)) {
      const firstPair = pairs.find((pair) => pair.token_in === tokenIn.id)
      setTokenOut(tokens.find((token) => token.id === firstPair?.token_out) || undefined)
    }
  }, [tokens, pairs, tokenIn, tokenOut])

  const getActualPair = (inVal, outVal) => {
    return pairs.find((item) => item.token_in === inVal.id && item.token_out === outVal.id);
  }

  const currentPair = useMemo(() => {
    if (!pairs.length || !tokenIn || !tokenOut) {
      return null;
    }

    return getActualPair(tokenIn, tokenOut);
  }, [pairs, tokenIn, tokenOut]);

  const init = (list, pairs, savedForm) => {
    const { token_in, token_out } = pairs[0] || {};

    const inObject = list.find((item) => item.id === token_in);
    const outObject = list.find((item) => item.id === token_out);

    setTokenIn(inObject);
    setTokenOut(outObject);

    if (savedForm) {
      formik.setValues(savedForm);
    }
  };

  useEffect(() => {
    if (refInitComplete.current || tokens.length === 0 || pairs.length === 0) {
      return;
    }

    init(tokens, pairs, formValues);
    refInitComplete.current = true;
  }, [tokens, pairs, formValues]);

  const courseText = useMemo(() => {
    if (!currentPair || !tokenIn) {
      return '';
    }

    return `Курс обмена: 1 ${tokenIn.name} = ${currentPair.rate}`;
  }, [currentPair, tokenIn]);

  const onSubmit = async (values) => {
    try {
      const body = {
        token_in: tokenIn.id,
        token_out: tokenOut.id,
        amount: Number(values.amountIn),
        // pan: (tokenOut.is_crypto && tokenOut) ? values.pan : Number(values.pan.split(' ').join('')),
        pan: values.pan,
        user_email: values.email,
        user_telegram: values.telegram
      }

      const operatorsRes = await callOperators(body);
      const isOperatorsError = operatorsRes.data.status === 'fail';

      if(!isOperatorsError) {
        const response = await call(body);
        onNext({...response.data, tokenOut: tokenOut});
        onSave(formik.values);
      } else {
        setMessage(<span>
          Обратитесь в поддержку <a href='https://t.me/EMIVN_EX_BOT'>@EMIVN_EX_BOT</a> для создания заявки.
        </span>);
      }
    } catch (err) {
      console.error(err);
      setMessage(<span>
        Обратитесь в поддержку <a href='https://t.me/EMIVN_EX_BOT'>@EMIVN_EX_BOT</a> для создания заявки.
      </span>);
    }
  };

  const validate = ({ amountIn, amountOut, pan, email, telegram, agreeTerms }) => {
    const errors = {};

    if (!Boolean(amountIn)) {
      errors.amountIn = 'Required';
    }
    if (+amountIn < currentPair.min_amount_in) {
      errors.amountIn = 'Less amount than needed'
    }
    if (+amountIn > currentPair.max_amount_in) {
      errors.amountIn = 'Less amount than needed'
    }

    if (!Boolean(amountOut)) {
      errors.amountOut = 'Required';
    }

    if (!(tokenOut && tokenOut.is_crypto)) {
      if (Boolean(pan) && pan.split(' ').every((item) => /^\d+$/.test(item)) === false) {
        errors.pan = 'Card error';
      }
    }

    if (!Boolean(pan)) {
      errors.pan = 'Required'
    }

    if (email && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) === false) {
      errors.email = 'Email error'
    }

    if (!email && !telegram) {
      errors.email = 'Required';
      errors.telegram = 'Required';
    }

    if (!agreeTerms) {
      errors.agreeTerms = 'Required';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      amountIn: '',
      amountOut: '',
      pan: '',
      email: '',
      telegram: '',
      agreeTerms: false,
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit,
    validate,
  });

  const onChangeIn = (token) => {
    setTokenIn(token);

    let out = tokenOut;

    if (tokenOut.id === token.id) {
      setTokenOut(outTokensList[0]);
      out = outTokensList[0];
    }

    if (formik.values.amountIn) {
      onChangeInAmount(formik.values.amountIn, getActualPair(token, out));
    }
  };

  const onChangeOut = (token) => {
    setTokenOut(token);

    if (formik.values.amountIn) {
      onChangeInAmount(formik.values.amountIn, getActualPair(tokenIn, token));
    }
  };


  const onChangeInAmount = useCallback((event, pair = currentPair) => {
    const value = typeof event === 'object' ? event.target.value : event;

    console.log('val', value);

    if (value < 0) {
      return
    }

    const newInNumber = +value.replace(/\s/g, '').replace(/[^0-9\.]/, '') || 0;

    formik.setFieldValue('amountIn', value.replace(/[^0-9\.]/, ''));
    const out = (newInNumber * pair.full_rate);
    formik.setFieldValue('amountOut', out);
  }, [formik.setFieldValue, currentPair]);

  const onChangeOutAmount = useCallback((event, pair = currentPair) => {
    const value = typeof event === 'object' ? event.target.value : event;

    if (value < 0) {
      return
    }

    const newOutNumber = +value.replace(/\s/, '').replace(/[^0-9\.]/, '') || 0;

    formik.setFieldValue('amountOut', value.replace(/[^0-9\.]/, ''));
    const inVal = (newOutNumber / pair.full_rate);
    formik.setFieldValue('amountIn', inVal);
  }, [formik.setFieldValue, currentPair]);

  const onChangeCb = useCallback(() => formik.setFieldValue('agreeTerms', !formik.values.agreeTerms), [formik.setFieldValue, formik.values.agreeTerms]);

  const onFromClipboard = useCallback(async () => {
    const text = await navigator.clipboard.readText();
    formik.setFieldValue('pan', text);
  }, [formik.setFieldValue]);

  const onSaveFormValues = useCallback(() => {
    onSave(formik.values)
  }, [formik.values]);

  const handleSwapToken = () => {
    setIsSwapped((prev) => !prev);
  }

  useEffect(() => {
    console.log('[new token in]', tokenIn)
  }, [tokenIn])

  useEffect(() => {
    console.log('[new token in]', tokenOut)
  }, [tokenOut])

  return (
    <>
      {message && (
        <Modal
          onClose={() => {
            setMessage(null)
            window.location.hash = ''
          }}
          label="Внимание"
          action="Ок"
          text={message}
        />
      )}
      <CardInner onSubmit={formik.handleSubmit}>
        <BackgroundLogo />
        <FadeIn delay={0.1}>
          <Field
            title="Отдаете"
          >
            <BorderedControls
              error={Boolean(formik.errors.amountIn)}
              dropRightPaddings
              left={
                <FieldWrap>
                  <Input
                    name="amountIn"
                    value={formatNumber(formik.values.amountIn, tokenIn ? tokenIn.dimension : 2)}
                    onChange={onChangeInAmount}
                    onBlur={formik.handleBlur}
                    placeholder="0.0000"
                  />
                </FieldWrap>
              }
              right={
                <Select
                  selected={tokenIn}
                  onSelect={onChangeIn}
                  options={inTokensList}
                />
              }
            />
            <HelpText>Минимум: {currentPair?.min_amount_in ?? ""} {tokenIn?.name ?? ""}</HelpText>
            {currentPair?.max_amount_in > 0 && <HelpText>Максимум: {currentPair?.max_amount_in ?? ""} {tokenIn?.name ?? ""}</HelpText>}
          </Field>
        </FadeIn>
        <FadeIn delay={0.3}>
          <SwapButton type="button" onClick={handleSwapToken}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_766_625)">
                <path d="M9.33301 4L9.33301 28" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.333 8L9.33301 4L5.33301 8" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M26.667 24L22.667 28L18.667 24" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M22.667 28V4" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_766_625">
                  <rect width="32" height="32" fill="white" transform="translate(0 32) rotate(-90)" />
                </clipPath>
              </defs>
            </svg>
          </SwapButton>
          <Field title="Получаете">
            <BorderedControls
              error={Boolean(formik.errors.amountOut)}
              left={
                <FieldWrap>
                  <Input
                    name="amountOut"
                    value={formatNumber(formik.values.amountOut, tokenOut ? tokenOut.dimension : 2)}
                    onChange={onChangeOutAmount}
                    onBlur={formik.handleBlur}
                    placeholder="0.0000"
                  />
                </FieldWrap>
              }
              right={
                <Select
                  selected={tokenOut}
                  onSelect={onChangeOut}
                  options={outTokensList}
                />
              }
            />
            <HelpText>{courseText}</HelpText>
          </Field>
        </FadeIn>
        <FadeIn delay={0.6}>
          <Field title={(tokenOut && tokenOut.is_crypto) ? "Номер кошелька" : "Номер карты"}>
            <BorderedControls
              error={Boolean(formik.errors.pan)}
              left={
                <FieldWrap>
                  <MaskedInput
                    name="pan"
                    value={formik.values.pan}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    mask={(tokenOut && tokenOut.is_crypto) ? false : [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
                    placeholder={(tokenOut && tokenOut.is_crypto) ? "ebadfaa92f1fd29e2fe..." : "0000 0000 0000 0000"}
                    render={(ref, props) => (
                      <Input
                        {...props}
                        ref={(node) => {
                          ref(node);
                          refPanInput.current = node;
                        }}
                      />
                    )}
                  />
                </FieldWrap>
              }
              button="Вставить"
              onClickButton={onFromClipboard}
            />
          </Field>
        </FadeIn>
        <FadeIn delay={0.8}>
          <Field title="Email или Telegram">
            <BorderedControls
              error={Boolean(formik.errors.email) || Boolean(formik.errors.telegram)}
              left={
                <FieldWrap>
                  <Input
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="mail@gmail.com"
                  />
                </FieldWrap>
              }
              right={
                <FieldWrap>
                  <Input
                    name="telegram"
                    value={formik.values.telegram}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="@username"
                  />
                </FieldWrap>
              }
            />
          </Field>
        </FadeIn>
        <FadeIn delay={1}>
          <Checkbox
            error={Boolean(formik.errors.agreeTerms)}
            checked={formik.values.agreeTerms}
            onChange={onChangeCb}
            caption={
              <CbCaption>Я согласен с <NavLink to="/faq" onClick={onSaveFormValues}>правилами обмена</NavLink></CbCaption>
            }
          />
        </FadeIn>

        {error && (
          <Warning>
            Повторите заявку на обмен через 5 минут или напишите в <a href="https://t.me/EMIVN_EX_BOT">службу поддержки клиентов</a>
          </Warning>
        )}

        <FadeIn delay={1.2}>
          <SubmitButton
            $loading={loading}
            type="submit"
          >{loading ? <Spinner /> : 'Обмен'}</SubmitButton>
        </FadeIn>
      </CardInner>
    </>
  )
};

export default FirstStep;
