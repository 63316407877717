import {
  HistoryContainer,
  Table,
  Header,
  Cell,
  Row,
  CellTitle,
} from "./styled";
import {
  ClipBorder,
} from "../../../../GlobalStyled";
import {useRequest} from "../../../../hooks/useRequest";
import {getHistory} from "../../../../api/request";
import {useEffect, useState} from "react";
import moment from "moment/moment";
import { formatNumber } from "../../../../utils/format";

const History = () => {
  const { call } = useRequest(() => Promise.all([getHistory()]));

  const [exchangeHistory, setExchangeHistory] = useState([]);

  const loadData = async () => {
    try {
      const [exchangeHistoryData] = await call();

      const exchangeHistory = exchangeHistoryData.data

      for (let i = 0; i < exchangeHistory.length; i++) {
        let date = new Date( exchangeHistory[i].date_op );
        exchangeHistory[i].date_op = moment(date).format('DD.MM.yyyy')
      }

      setExchangeHistory(exchangeHistory);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <HistoryContainer>
      <ClipBorder>
        <Table>
          <Row cols={6}>
            <Header>Номер заявки</Header>
            <Header>Дата</Header>
            <Header>Кошелек</Header>
            <Header>Сумма</Header>
            <Header>Курс</Header>
            <Header>Статус</Header>
          </Row>
          {exchangeHistory.map((item) => (
            <Row key={item.order_id} cols={6}>
              <Cell>
                <CellTitle>Номер заявки</CellTitle>
                <span>№{item.order_id}</span>
              </Cell>
              <Cell>
                <CellTitle>Дата</CellTitle>
                <span>{item.date_op}</span>
              </Cell>
              <Cell>
                <CellTitle>Кошелек</CellTitle>
                <span>{item.wallet}</span>
              </Cell>
              <Cell>
                <CellTitle>Сумма</CellTitle>
                <span>{item.amountIn} {item.tokenIn} {'->'} {item.amountOut} {item.tokenOut}</span>
              </Cell>
              <Cell>
                <CellTitle>Курс</CellTitle>
                <span>{formatNumber(item.amountOut / item.amountIn)}</span>
              </Cell>
              <Cell>
                <CellTitle>Статус</CellTitle>
                <span>{item.exchangeStatus}</span>
              </Cell>
            </Row>
          ))}
        </Table>
      </ClipBorder>
    </HistoryContainer>
  )
};

export default History;
