import {
  Avatar,
  Burger,
  ButtonBurger,
  ButtonCloseContainer,
  CloseButton,
  MobileMenuHeader,
  StyledButtons,
  StyledInnerNav,
  StyledMobileNav,
  StyledPopup
} from "../styled/MenuStyled";
import MENU_LINKS from "../../../constants/menu";
import {
  NavLink,
  useNavigate
} from "react-router-dom";
import {
  Container,
  FadeIn
} from "../../../GlobalStyled";
import {
  AuthButton,
  SupportButton
} from "../styled/HeaderStyled";
import Logotype from "../../../assets/images/emivn_logo.png";

const MobileMenu = ({ open, onClose, onOpen, auth }) => {

  const navigate = useNavigate();

  const goTo = (path) => () => {
    navigate(path);
    onClose();
  };

  return (
    <StyledPopup
      modal
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      trigger={
        <ButtonBurger>
          <Burger />
        </ButtonBurger>
      }
      className="mobile-menu"
      closeOnDocumentClick
    >
      <Container>
        <StyledMobileNav>
          <StyledInnerNav>
            <MobileMenuHeader>
              <Avatar>
                <img src={Logotype} alt=""/>
              </Avatar>
              <ButtonCloseContainer onClick={onClose}>
                <CloseButton/>
              </ButtonCloseContainer>
            </MobileMenuHeader>
            <ul>
              {MENU_LINKS.filter(
                  (item) => auth ? true : !item.onAuth
              ).map(({ label, link}) => (
                <li
                  key={link}
                >
                  <NavLink to={link} onClick={onClose}>{label}</NavLink>
                </li>
              ))}
            </ul>
          </StyledInnerNav>
        </StyledMobileNav>
        <StyledButtons>
          <FadeIn delay={0.2}>
            <SupportButton onClick={goTo('/contacts')}>Поддержка 24/7</SupportButton>
          </FadeIn>
          <FadeIn delay={0.4}>
            <AuthButton onClick={goTo(auth ? '/auth/sign-out' : '/auth')}>{auth ? 'Выйти' : 'Войти'}</AuthButton>
          </FadeIn>
          </StyledButtons>
      </Container>
    </StyledPopup>
  )
};

export default MobileMenu;
