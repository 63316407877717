import StyledSpin from "./styled";

const Spinner = () => (
  <StyledSpin>
    <div></div>
    <div></div>
    <div></div>
  </StyledSpin>
);

export default Spinner;
