import styled from "styled-components";

const CometPosition = styled.div`
position: absolute;
bottom: 0;
left: 0;
width: 100vw;
height: fit-content;
transition: all .3s ease;
transform: scale(0.4);
transform-origin: bottom;

img {
  position: relative;
  z-index: -1;
  width: 100%;
  height: auto;
  animation: cometMove 9s linear infinite forwards;
}

@keyframes cometMove {
  0% {
    transform: translateX(-100%) translateY(100%);
  }

  20% {
    transform: translateX(200%) translateY(-200%);
  }

  100% {
    transform: translateX(200%) translateY(-200%);
  }
}

@media (max-width: ${props => props.theme.breakpoints.sm}) {
  @keyframes cometMove {
    0% {
      transform: translateX(-200%) translateY(-200px);

    }
  
    20% {
      transform: translateX(200%) translateY(-900px);
    }
  
    100% {
      transform: translateX(200%) translateY(-900px);
    }
  }
}
`;

const EarthLeft = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-10%, 30%);
  transition: all .3s ease;

  img {
    position: relative;
    z-index: -1;
  }
  
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    //background: #0D68C0;
    opacity: 0.7;
    //filter: blur(230px);
    position: absolute;
    top: 0;
    z-index: -1;
  }


  @media (max-width: 1700px) {
    transform: translate(-25%, 30%);
  }

  @media (max-width: 1400px) {
    transform: translate(-35%, 40%);
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
`;

const EarthRight = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(0, 50%);
  
  img {
    position: relative;
    z-index: -1;
  }
  
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    //background: #0D68C0;
    opacity: 0.7;
    //-webkit-filter: blur(230px);
    //filter: blur(230px);
    position: absolute;
    top: 0;
    z-index: -1;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }

  @media (max-width: 1700px) {
    transform: translate(25%, 50%);
  }
`;

const EarthMobile = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: none;
  
  img {
    position: relative;
    z-index: -1;
    width: inherit;
  }
  
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    position: absolute;
    top: 0;
    z-index: 2;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: block;
    transform: translateY(20%);
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    transform: translateY(0%);
  }
`;

const ExoPosition = styled.div`
  position: absolute;
  bottom: 20%;
  right: 20%;
  animation: scale 25s linear infinite;

  img {
    position: relative;
    z-index: -1;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }


  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
`;


const StarPosition = styled.div`
  position: absolute;
  top: 10%;
  left: 1%;
  animation: scale1 11s 2s linear infinite;

  img {
    position: relative;
    z-index: -1;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }


  @keyframes scale1 {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const NexPosition = styled.div`
  position: absolute;
  bottom: 10%;
  left: 20%;
  animation: move 10s linear infinite;

  img {
    position: relative;
    z-index: -1;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }


  @keyframes move {
    0% {
      transform: translate(0) scale(1);
    }
    25% {
      transform: translateY(30%) scale(.6);
    }
    50% {
      transform: translateY(-30%) scale(.8);
    }
    75% {
      transform: translateZ(40%) scale(.4);
    }
    100% {
      transform: translateY(0) scale(1);
    }
  }
`;

const ElipsePosition = styled.div`
  position: absolute;
  width: 100%;
  top: 65%;
  animation: rotate 60s linear infinite;
  
  img {
    position: relative;
    z-index: -1;
    width: inherit;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    50% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0);
    }
  }


  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: none;
  }
`;

export {
  EarthLeft,
  EarthMobile,
  ElipsePosition,
  ExoPosition,
  EarthRight,
  StarPosition,
  NexPosition,
  CometPosition
}
