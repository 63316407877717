import styled from "styled-components";
import { CommonButton } from "../../../GlobalStyled";

const ModalStyled = styled.div`
  width: auto;
  height: auto;
  padding: 24px;
  background: #00000050;
  clip-path: polygon(0 20.00px, 20.00px 0, 100% 0, 100% calc(100% - 20.00px),calc(100% - 20.00px) 100%,0 100%,0 20.00px);

  --gradient: linear-gradient(153.42deg, rgba(27, 143, 255, 0) 0%, rgba(27, 143, 255, 0.842105) 42.71%, rgba(27, 143, 255, 0) 100%),
  linear-gradient(0deg, rgba(10, 10, 10, 0.13), rgba(10, 10, 10, 0.13));

  position: relative; 
  transition: opacity .6s ease-in;
  opacity: ${({ hidden }) => hidden ? 0 : 1};
  margin: 0 auto;
  min-width: 300px;

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    background: var(--gradient);
    background-size: 400% 400%;
    clip-path: polygon(0 20.00px,20.00px 0,100% 0,100% calc(100% - 20.00px),calc(100% - 20.00px) 100%,0 100%,0 20.00px,4px  calc(20.00px + 1.66px),4px calc(100% - 4px),calc(100% - 20.00px - 1.66px) calc(100% - 4px),calc(100% - 4px) calc(100% - 20.00px - 1.66px),calc(100% - 4px) 4px,calc(20.00px + 1.66px) 4px,4px calc(20.00px + 1.66px));

    animation: gradient 5s ease infinite;
  }
`;

const ModalWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
background: #00000050;
backdrop-filter: blur(10px);
z-index: 10;


animation: modal_fadein 0.5s ease 1;

@keyframes modal_fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
`;

const ModalLabel = styled.span`
text-align: center;
display: block;
font-size: 21px;
margin-bottom: 12px;
`;

const ModalText = styled.span`
text-align: center;
display: block;
font-size: 16px;

* {
  display: inline;
  margin: 0;
  padding: 0;
}
`;

const ModalButton = styled(CommonButton)`
position: absolute;
top: 12px;
right: 12px;

> svg {
  width: 16px;
  height: 16px;
}
`;

export { ModalStyled, ModalWrapper, ModalButton, ModalText, ModalLabel };
