import {
    Container,
    MainContent,
    RootInner,
    RootStyled
} from "./GlobalStyled";
import Header from "./components/Header";
import {useRoutes} from 'react-router-dom';
import ROUTES from "./routes";
import {
    useRef,
    useState
} from "react";
import BackgroundImages from "./components/BackgroudImages";
import ExchangeFormProvider from "./providers/ExchangeFormProvider";
import {useScrollPageTop} from "./hooks/useScrollPageTop";
import Cookies from "js-cookie";


const App = () => {
    const routes = useRoutes(ROUTES);

    const refInner = useRef();
    useScrollPageTop(refInner);

    const [menuOpened, setMenuOpened] = useState(false);

    const onOpen = () => setMenuOpened(true);
    const onClose = () => setMenuOpened(false);

    return (
        <RootStyled>
            <BackgroundImages/>
            <RootInner ref={refInner}>
                <Container>
                    <Header
                        open={menuOpened}
                        onOpen={onOpen}
                        onClose={onClose}
                        auth={!!Cookies.get("token")}
                    />
                </Container>
                <ExchangeFormProvider>
                    <Container>
                        <MainContent hidden={menuOpened}>
                            {routes}
                        </MainContent>
                    </Container>
                </ExchangeFormProvider>
            </RootInner>
        </RootStyled>
    );
}

export default App;
