import styled, {
  createGlobalStyle,
  css
} from "styled-components";
import StarsImage from './assets/images/stars.png';
import LogoImageTitled from './assets/images/emivn_logo_titled.png';

const GlobalStyled = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    position: relative;
    font-weight: normal;
  }
  
  :root {
    --text-color: #FFFFFF;
    --bg-color: #151515;
    --accent-color: #1B8FFF;
    --accent-gradient: linear-gradient(0deg, rgba(81, 81, 81, 0.2), rgba(81, 81, 81, 0.2)), linear-gradient(51.06deg, rgba(27, 143, 255, 0) 32.69%, #1B8FFF 41.04%, #1B8FFF 73.09%, rgba(27, 143, 255, 0) 80.87%), linear-gradient(351.15deg, rgba(27, 143, 255, 0) 29.45%, #1B8FFF 38.83%, #1B8FFF 59.16%, rgba(27, 143, 255, 0) 83.59%);
    --gray-color: #676767;
    --red-color: #FF4E20;
    --container-padding: 20px;
    --container-width: 1180px;
    --sub-container-width: 580px;
    --border-gradient: linear-gradient(351.15deg, rgba(27, 143, 255, 0) 29.45%, #1B8FFF 38.83%, #1B8FFF 59.16%, rgba(27, 143, 255, 0) 83.59%),
    linear-gradient(51.06deg, rgba(27, 143, 255, 0) 32.69%, #1B8FFF 41.04%, #1B8FFF 73.09%, rgba(27, 143, 255, 0) 80.87%),
    linear-gradient(0deg, rgba(81, 81, 81, 0.2), rgba(81, 81, 81, 0.2)),
    linear-gradient(0deg, rgba(26, 26, 26, 0.4), rgba(26, 26, 26, 0.4));
  }

  body {
    color: var(--text-color);
    background: var(--bg-color);
    transition: color 0.5s, background-color 0.5s;
    font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    :root {
      //--container-width: 850px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    :root {
      --container-width: 600px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    :root {
      //--container-width: 350px;
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    height: 100%;
    font-weight: bold;
    font-size: 13px;
    color: var(--text-color);
    text-decoration: none;
  }
  
  li {
    list-style: none;
  }
  ul {
    padding: 0;
    margin: 0;
  }
  strong {
    font-weight: bold;
  }
`;

const RootStyled = styled.div`
  min-height: 100vh;
  background-image: url(${StarsImage});
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RootInner = styled.div`
  height: 100vh;
  overflow-y: auto;
  overflow-y: overlay;
  overflow-x: hidden;
  padding: 20px 0 0 0;
  position: relative;
  z-index: 1;
  width: 100%;
  
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px var(--bg-color--accent-color);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--accent-color);
  }
`;

const Container = styled.div`
  max-width: calc(var(--container-width) + var(--container-padding));
  width: 100%;
  margin: 0 auto;
  padding: 0 var(--container-padding);
`;

const SubContainer = styled.div`
  max-width: calc(var(--sub-container-width) + var(--container-padding));
  width: 100%;
  margin: 0 auto;
`;

const CommonButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const MainContent = styled.main`
  width: 100%;
  //display: flex;
  flex-direction: column;
  //justify-content: center;
  //align-items: center;
  //
  position: relative;
  z-index: 10;
  opacity: ${({ hidden }) => hidden ? 0 : 1};
`;

const SubTitle = styled.span`
  display: block;
  font-size: 32px;
  font-weight: 500;
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    font-size: 20px;
  }
`;

const BackgroundLogo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;

  &:after {
    position: sticky;
    top: calc(50dvh - 160px);
    content: "";
    display: block;
    height: 320px;
    max-height: 100%;
    width: 100%;
    margin: 0 auto;
    opacity: 0.2;
    background: url(${LogoImageTitled}) no-repeat center;
    background-size: contain;
  }
`;

const FadeIn = styled.div`
  animation: fade-in .4s ${({delay = 0}) => `${delay}s`} ease-out backwards;
  
  @keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(15%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const ClipBorder = styled.div`
  position: relative;
  width: 100%;

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    background: var(--border-gradient);
    clip-path: polygon(0 20.00px,20.00px 0,100% 0,100% calc(100% - 20.00px),calc(100% - 20.00px) 100%,0 100%,0 20.00px,4px  calc(20.00px + 1.66px),4px calc(100% - 4px),calc(100% - 20.00px - 1.66px) calc(100% - 4px),calc(100% - 4px) calc(100% - 20.00px - 1.66px),calc(100% - 4px) 4px,calc(20.00px + 1.66px) 4px,4px calc(20.00px + 1.66px));
    background-size: 400% 100%;
    
    ${({noAnimation}) => noAnimation ? undefined : css`
      animation: gradient 5s ease infinite;
    `}
    
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      animation: none;
    }
  }
  &:after {
    content: '';
    position: absolute;
    inset: 0;
    background: rgba(26, 26, 26, 0.4);
    z-index: -1;
    clip-path: polygon(0 20.00px,20.00px 0,100% 0,100% calc(100% - 20.00px),calc(100% - 20.00px) 100%,0 100%);
  }
`;

const ListElement = styled.li`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
    background: linear-gradient(90deg, rgba(27, 143, 255, 0) 0%, #1B8FFF 44.79%, rgba(27, 143, 255, 0) 100%);
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
`;

const Card = styled.article`
  width: 100%;

  // @media (max-width: ${props => props.theme.breakpoints.md}) {
  //   width: var(--container-width);
  // }
  //
  // @media (max-width: ${props => props.theme.breakpoints.xs}) {
  //   width: var(--container-width);
  // }
`;


export { BackgroundLogo, RootStyled, CommonButton, MainContent, SubTitle, FadeIn, ClipBorder, ListElement, Card, RootInner, Container, SubContainer };
export default GlobalStyled;
