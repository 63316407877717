import styled, { css } from "styled-components";


const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 12px;
  cursor: pointer;
`;



const Mark = styled.div`
  position: relative;
  height: 22px;
  width: 22px;

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    background: var(--accent-color);
    clip-path: polygon(0 4.00px,4.00px 0,100% 0,100% calc(100% - 4.00px),calc(100% - 4.00px) 100%,0 100%);
  }
`;


const CbContainer = styled.div`
  position: relative;
  height: 32px;
  width: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    transition: all .2s ease-in;
    content: "";
    position: absolute;
    inset: 0;
    background: ${({ checked, error }) => checked ? 'var(--accent-color)' : error ? 'var(--red-color)' : 'var(--gray-color)'};
    clip-path: polygon(0 4.00px,4.00px 0,100% 0,100% calc(100% - 4.00px),calc(100% - 4.00px) 100%,0 100%,0 4.00px,2px  calc(4.00px + 0.83px),2px calc(100% - 2px),calc(100% - 4.00px - 0.83px) calc(100% - 2px),calc(100% - 2px) calc(100% - 4.00px - 0.83px),calc(100% - 2px) 2px,calc(4.00px + 0.83px) 2px,2px calc(4.00px + 0.83px));
  }
  
  &:hover {
    &:before {
      background: var(--accent-color);
    }
  }
  
  ${({ checked }) => css`
      ${Mark} {
        &:before {
          background: ${checked ? 'var(--accent-color)' : 'transparent'};
        }
      }
  `}
`;

const CbText = styled.span`
  color: ${({ error }) => error ? 'var(--red-color)' : 'var(--text-color)'};
`;

export default StyledWrapper;
export {
  CbContainer,
  Mark,
  CbText,
};
