import styled from "styled-components";

const PasswordWrap = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  
  a {
    padding: 0;
    color: var(--gray-color);
    text-transform: uppercase;
    text-decoration: underline;
    text-underline-offset: 2px;
    font-size: 13px;
    align-self: flex-end;
  }
`;

export {
  PasswordWrap
};
