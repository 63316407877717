import styled, { css } from "styled-components";
import {
  Card,
  ClipBorder,
  CommonButton
} from "../../GlobalStyled";

const ContainerChange = styled(Card)`
  padding: 40px 0;
  display: flex;
  flex-direction: column;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 100px 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    padding: 50px 0 150px 0
  }
  
  ${({success}) => success && css`
    ${ClipBorder} {
      &:first-child {
        &:before {
          content: "";
          position: absolute;
          inset: 0;
          background: linear-gradient(351.15deg,rgba(27,143,255,0) 29.45%,#1bff37 38.83%,#1bff37 59.16%,rgba(27,143,255,0) 83.59%), linear-gradient(51.06deg,rgba(27,143,255,0) 32.69%,#1bff37 41.04%,#1bff37 73.09%,rgba(27,143,255,0) 80.87%), linear-gradient(0deg,rgba(81,81,81,0.2),rgba(81,81,81,0.2)), linear-gradient(0deg,rgba(26,26,26,0.4),rgba(26,26,26,0.4));;
          clip-path: polygon(0 20.00px,20.00px 0,100% 0,100% calc(100% - 20.00px),calc(100% - 20.00px) 100%,0 100%,0 20.00px,4px  calc(20.00px + 1.66px),4px calc(100% - 4px),calc(100% - 20.00px - 1.66px) calc(100% - 4px),calc(100% - 4px) calc(100% - 20.00px - 1.66px),calc(100% - 4px) 4px,calc(20.00px + 1.66px) 4px,4px calc(20.00px + 1.66px));

          background-size: 400% 100%;
          animation: gradient 5s ease infinite;
          
          @media (max-width: ${props => props.theme.breakpoints.md}) {
            animation: none;
          }
        }
      }
    }
  `}
`;

const CardInner = styled.form`
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  grid-gap: ${({gap}) => gap || '32px'};
`;

const BlurBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    background: rgba(26, 26, 26, 0.2);
    //backdrop-filter: blur(4px);
    clip-path: polygon(0 20.00px,20.00px 0,100% 0,100% calc(100% - 20.00px),calc(100% - 20.00px) 100%,0 100%);
  }
`;

const FieldWrap = styled.div`
  padding: 0 20px;
  height: 100%;
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    padding: 0 10px;
  }
`;

const Input = styled.input`
  font-size: 18px;
  color: var(--text-color);
  border: none;
  height: 100%;
  width: 68%;
  outline: none;
  background: transparent;

  // Костыль для отключения blink
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // Костыль для отключения firefox
  -moz-appearance: textfield;

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    font-size: 16px;
  }
`;

const InputDisabled = styled.input`
  font-size: 18px;
  color: var(--text-color);
  border: none;
  height: 100%;
  width: 100%;
  outline: none;
  background: transparent;
  pointer-events: none;

  // Костыль для отключения blink
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // Костыль для отключения firefox
  -moz-appearance: textfield;

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    font-size: 16px;
  }
`;

const SubmitButton = styled(CommonButton)`
  color: var(--accent-color);
  width: 100%;
  height: 80px;
  font-size: 24px;
  
  ${({ $loading }) => $loading && css`
    cursor: default;
    pointer-events: none;
  `}
  
  &:before {
    content: "";
    position: absolute;
    inset: 0;
    background: var(--accent-color);
    clip-path: polygon(0 20.00px,20.00px 0,100% 0,100% calc(100% - 20.00px),calc(100% - 20.00px) 100%,0 100%,0 20.00px,4px  calc(20.00px + 1.66px),4px calc(100% - 4px),calc(100% - 20.00px - 1.66px) calc(100% - 4px),calc(100% - 4px) calc(100% - 20.00px - 1.66px),calc(100% - 4px) 4px,calc(20.00px + 1.66px) 4px,4px calc(20.00px + 1.66px));
  }
  
  &:hover {
    color: var(--text-color);
    background: ${({$loading}) => $loading ? 'transparent' : 'var(--accent-color)'};
    clip-path: polygon(0 20.00px,20.00px 0,100% 0,100% calc(100% - 20.00px),calc(100% - 20.00px) 100%,0 100%);
  }
`;

const Warning = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${({success}) => success ? '#61eb6d' : 'var(--red-color)'};
  padding: 20px;
  animation: appear .4s .2s ease-out backwards;
  text-align: ${({textCenter}) => textCenter ? 'center' : 'left'};
  
  a {
    text-decoration: underline;
    font-size: 18px;
    font-weight: normal;
    display: inline;
    padding-left: 5px;
  }

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    padding: 20px 10px;
    font-size: 16px;
  }

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    background: currentColor;
    clip-path: polygon(0 20.00px,20.00px 0,100% 0,100% calc(100% - 20.00px),calc(100% - 20.00px) 100%,0 100%,0 20.00px,2px  calc(20.00px + 0.83px),2px calc(100% - 2px),calc(100% - 20.00px - 0.83px) calc(100% - 2px),calc(100% - 2px) calc(100% - 20.00px - 0.83px),calc(100% - 2px) 2px,calc(20.00px + 0.83px) 2px,2px calc(20.00px + 0.83px));
  }

  @keyframes appear {
    from {
      opacity: 0;
      scale: 0;
    }
    to {
      opacity: 1;
      scale: 1;
    }
  }
`;

const BackButton = styled(CommonButton)`
  color: var(--text-color);
  font-size: 24px;
  padding: 25px 0;
  &:hover {
    text-decoration: underline;
    text-underline-offset: 6px;
  }
  
  ${({small}) => small && css`
    font-size: 16px;
    padding: 15px 0;
    font-weight: bold;
  `}
`;

const BackWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const OptionWrap = styled.div`
  margin: -5px 0;
`;

const CbCaption = styled.span`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 4px;
  a {
    font-size: 16px;
    font-weight: 400;
    text-decoration: underline;
    text-underline-offset: 4px;
    cursor: pointer;
    padding: 0;
  }
`;

const HelpText = styled.span`
  display: block;
  margin-top: -8px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #676767;
`;

const SwapButton = styled.button`
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  min-width: 48px;
  width: 48px;
  height: 48px;
  font-size: 0;
  background: #1B8FFF;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const SuccessStyled = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 30px;
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    font-size: 20px;
  }
`;

export default ContainerChange;
export {
  CardInner,
  Input,
  InputDisabled,
  BlurBackground,
  FieldWrap,
  SubmitButton,
  BackButton,
  Warning,
  BackWrapper,
  OptionWrap,
  SuccessStyled,
  CbCaption,
  HelpText,
  SwapButton
}
