import styled from "styled-components";
import { SubTitle } from "../../../../GlobalStyled";


const List = styled.ul`
  display: flex;
  align-items: center;
  grid-gap: 24px;
  padding: 0 0 24px 0;

  ${SubTitle} {
    transition: all .3s ease-in;
    color: var(--gray-color)!important;

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 24px;
    }
    @media (max-width: ${props => props.theme.breakpoints.xs}) {
      font-size: 20px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    justify-content: space-between;
    width: 100%;
    grid-gap: 16px;
  }
  
  .active {
    ${SubTitle} {
      color: var(--text-color)!important;
    }
  }
  
  .history {
    display: inline;
    @media (max-width: ${props => props.theme.breakpoints.xs}) {
      display: none;
    }
  }
  .history-xs {
    display: none;
    @media (max-width: ${props => props.theme.breakpoints.xs}) {
      display: inline;
    }
  }
  
  a {
    padding: 0;
  }
`;

export {
  List,
}
