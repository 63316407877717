import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useScrollPageTop = (ref) => {
  const { pathname } = useLocation();

  useEffect(() => {
    ref.current?.scrollTo(0, 0);
  }, [pathname]);

};

export {
  useScrollPageTop
};
