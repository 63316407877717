import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getExchangeStatusById } from "../../api/request";
import { useRequest } from "../../hooks/useRequest";
import {
  BackButton,
  BackWrapper,
  ContainerStatus,
  Warning,
  StatusClipBorder,
  OrderNumber
} from "./styled";
import { FadeIn, SubTitle, SubContainer } from "../../GlobalStyled";
import { BlurBackground } from "../Exchange/styled";

const ExchangeStatus = () => {
  const [orderStatus, setOrderStatus] = useState({});

  const navigate = useNavigate()
  const { id } = useParams();
  const { call, error } = useRequest(() => getExchangeStatusById(id))

  const fetch = async () => {
    try {
      const res = await call();
      setOrderStatus(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetch();

    const intervalId = setInterval(() => {
      fetch();
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);


  const status = {
    process: !orderStatus.fail && !orderStatus.initial && !orderStatus.cancel && !orderStatus && !orderStatus.done,
    error: !(orderStatus.done || orderStatus.process || orderStatus.confirm),
    success: orderStatus.done,
  }

  let statusMessage = ""

  if (orderStatus.process || orderStatus.confirm) {
    statusMessage = 'Заявка находится в обработке'
  }

  if (orderStatus.cancel) {
    statusMessage = 'Ваша заявка отклонена'
  }

  if (orderStatus.done) {
    statusMessage = 'Ваша заявка выполнена'
  }

  if (orderStatus.fail) {
    statusMessage = 'Ваша заявка отклонена как ошибочная'
  }

  if (!orderStatus) return <></>;

  return (
    <FadeIn>
      <SubContainer>
        <ContainerStatus>
          <SubTitle>Статус заявки</SubTitle>
          <StatusClipBorder>
            <BlurBackground/>
            { error 
              ? <Warning error textCenter>Произошла ошибка</Warning>
              : (
                <>
                  <OrderNumber>Заявка №{id}</OrderNumber>
                  <Warning {...status} textCenter>{statusMessage}</Warning>
                </> 
              )
            }
            <BackWrapper>
              <BackButton
                small
                type="button"
                onClick={() => navigate('/')}>Вернуться назад</BackButton>
            </BackWrapper>
          </StatusClipBorder>
        </ContainerStatus>
      </SubContainer>
    </FadeIn>
  );
};

export default ExchangeStatus;
