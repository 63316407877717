import {
  createContext,
  useContext
} from "react";

const ExchangeFormContext = createContext({});

const useExchangeFormContext = () => useContext(ExchangeFormContext);

export default ExchangeFormContext;
export {
  useExchangeFormContext
};
