import styled from "styled-components";
import { CommonButton } from "../../../GlobalStyled";
import Popup from "reactjs-popup";

const NavStyled = styled.nav`
  display: flex;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: none;
  }
`;

const MenuListStyled = styled.ul`
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  display: flex;
  li {
    &:hover {
      background: linear-gradient(0deg, rgba(81, 81, 81, 0.2), rgba(81, 81, 81, 0.2)), linear-gradient(1.42deg, rgba(27, 143, 255, 0) -120.54%, #1B8FFF -108.38%, #1B8FFF -29.06%, rgba(27, 143, 255, 0) 46.15%);
    }
  }
`;

const Avatar = styled.div`
  padding: 0px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  align-self: center;
  margin: 0 25px 0 20px;
  & > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Burger = styled.span`
  display: block;
  width: 35px;
  height: 2px;
  position: relative;
  background: currentColor;
  &:before {
    content: '';
    position: absolute;
    top: -10px;
    width: 35px;
    height: 2px;
    display: block;
    background: currentColor;
  }
  &:after {
    content: '';
    position: absolute;
    top: 10px;
    width: 35px;
    height: 2px;
    display: block;
    background: currentColor;
  }


  transition: all .3s ease-in;
`;

const ButtonBurger = styled(CommonButton)`
  display: none;
  height: 35px;
  padding: 5px;
  color: var(--text-color);

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: block;
  }
  
  &:hover {
    color: var(--accent-color);
  }
`;

const StyledPopup = styled(Popup)`
  &-content {
    margin: 0 auto!important;
    background: transparent;
    border: none;
    width: 100%;
    height: fit-content;
  }
`;

const StyledMobileNav = styled.nav`
  margin: 0 auto;
  animation: height .3s ease-out forwards;
  padding: 15px 0 0 0;

  @keyframes height {
    from {
      transform: translateX(50%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
`;

const StyledInnerNav = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  
  &:before {
    content: "";
    position: absolute;
    inset: 0;
    background: var(--border-gradient);
    clip-path: polygon(0 10.00px,10.00px 0,100% 0,100% calc(100% - 10.00px),calc(100% - 10.00px) 100%,0 100%,0 10.00px,4px  calc(10.00px + 1.66px),4px calc(100% - 4px),calc(100% - 10.00px - 1.66px) calc(100% - 4px),calc(100% - 4px) calc(100% - 10.00px - 1.66px),calc(100% - 4px) 4px,calc(10.00px + 1.66px) 4px,4px calc(10.00px + 1.66px));
  }
  
  --line-gradient: linear-gradient(90deg, rgba(27, 143, 255, 0) 0%, #1B8FFF 44.79%, rgba(27, 143, 255, 0) 100%);

  a {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 18px 20px;
    font-size: 20px;
    text-align: right;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      opacity: 0.3;
      background: var(--line-gradient);
    }
  }
  ul {
    padding: 0;
  }
  li {
    &:last-child {
      a:after {
        display: none;
      }
    }
    &:hover {
      background: linear-gradient(0deg, rgba(81, 81, 81, 0.2), rgba(81, 81, 81, 0.2)), linear-gradient(1.42deg, rgba(27, 143, 255, 0) -120.54%, #1B8FFF -108.38%, #1B8FFF -29.06%, rgba(27, 143, 255, 0) 46.15%);
    }
  }
`;

const ButtonCloseContainer = styled(CommonButton)`
  color: var(--text-color);
  height: 35px;
  padding: 5px;
  
  &:hover {
    color: var(--accent-color);
  }
`;

const CloseButton = styled.span`
  position: relative;
  display: block;
  width: 35px;
  height: 2px;
  transform: rotate(45deg);
  background: currentColor;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    transform: rotate(-90deg);
    display: block;
    width: 35px;
    height: 2px;
    background: currentColor;
  }
`;

const MobileMenuHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 14px 20px 0;
`;

const StyledButtons = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
  padding: 40px 0 0 0;
  button {
    display: block!important;
    width: 100%;
    height: 80px;
  }
`;


export { NavStyled, Avatar, Burger, ButtonBurger, StyledPopup, StyledMobileNav, StyledInnerNav, CloseButton, MobileMenuHeader, ButtonCloseContainer, StyledButtons };
export default MenuListStyled;

