import {Navigate} from "react-router-dom";
import Reserves from "./pages/Reserves";
import Exchange from "./pages/Exchange";
import ExchangeStatus from "./pages/ExchangeStatus";
import Contacts from "./pages/Contacts";
import Faq from "./pages/Faq";
import Account from "./pages/Account";
import Settings from "./pages/Account/subpages/Settings";
import History from "./pages/Account/subpages/History";
import Payments from "./pages/Account/subpages/Payments";
import Auth from "./pages/Auth";
import Login from "./pages/Auth/subpages/Login";
import SignUp from "./pages/Auth/subpages/SignUp";
import Restore from "./pages/Auth/subpages/Restore";

const ROUTES = [
    {
        path: '',
        element: <Exchange/>
    },
    {
        path: '/confirm/:id',
        element: <Exchange/>
    },
    {
        path: 'exchange-status/:id',
        element: <ExchangeStatus/>,
    },
    {
        path: 'reserves',
        element: <Reserves/>,
    },
    {
        path: 'contacts',
        element: <Contacts/>,
    },
    {
        path: 'faq',
        element: <Faq/>,
    },
    {
        path: 'account',
        element: <Account/>,
        children: [
            {
                index: true,
                element: (
                    <Navigate
                        to='settings'
                        replace
                    />
                )
            },
            {
                path: 'settings',
                element: <Settings/>
            },
            {
                path: 'history',
                element: <History/>
            },
            {
                path: 'payments',
                element: <Payments/>
            }
        ]
    },
    {
        path: 'auth',
        element: <Auth/>,
        children: [
            {
                index: true,
                element: (
                    <Navigate
                        to='sign-in'
                        replace
                    />
                )
            },
            {
                path: 'sign-in',
                element: <Login/>
            },
            {
                path: 'sign-in/:code',
                element: <Login/>
            },
            {
                path: 'sign-out',
                element: <Login/>
            },
            {
                path: 'sign-up',
                element: <SignUp/>
            }
        ]
    },
    {
        path: 'restore',
        element: <Restore/>
    },
    {
        path: '*',
        element: <Navigate to="/"/>,
    },
];

export default ROUTES;
