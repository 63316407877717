import {
    ClipBorder,
    FadeIn
} from "../../../../GlobalStyled";
import {
    SettingsContainer,
    SettingsInner
} from "../../styled";
import BorderedControls from "../../../Exchange/components/BorderedControls/BorderedControls";
import {
    FieldWrap,
    Input,
    SubmitButton
} from "../../../Exchange/styled";
import Field from "../../../Exchange/components/Field/Field";
import PasswordField from "../../../../components/PasswordField/PasswordField";
import { useRequest } from "../../../../hooks/useRequest";
import { getUser, repeatConfirm, updateUser } from "../../../../api/request";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import Spinner from "../../../../components/Spinner/Spinner";
import Modal from "../../../../components/Modal/Modal";

const Settings = () => {
    const { call } = useRequest(() => Promise.all([getUser()]));

    const [message, setMessage] = useState(null);
    const [userData, setUserData] = useState({});
    const [login, setLogin] = useState([]);
    const [loading, setLoading] = useState(false);

    const loadData = async () => {
        try {
            const [data] = await call();
            console.log(data)
            setLogin(data.data.email);
            setUserData(data.data)
        } catch (err) {
            console.error(err);
        }
    }

    const validateEmail = (value) => {
        let error;
        if (!value) {
            error = 'Обязательное поле';
        } else if (
            !/^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(value)
        ) {
            error = 'Неверный email'
        }
        return error;
    };

    useEffect(() => {
        loadData();
    }, []);

    const onSubmit = async ({ current_password, new_password, confirm_password }) => {
        try {
            setLoading(true);
            await updateUser({ current_password, new_password, confirm_password });
            // login:"bppfizmhu@arxxwalls.com"password:"123123"
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
            alert("Пароль изменен!");
        }
    };

    const formik = useFormik({
        initialValues: {
            current_password: '',
            new_password: '',
            confirm_password: '',
        },
        validateOnBlur: false,
        validateOnChange: true,
        onSubmit,
    });

    const handleEmail = () => {
        setLoading(true);
        setLoading(false);

        const fetch = async () => {
            const res = await repeatConfirm({
                email: login
            })
                .then((res) => {
                    setMessage("Ссылка для подтверждения отправлена на ваш Email");
                })
                .catch((err) => {
                    setMessage('Что-то пошло не так')
                });
        }
        fetch();
    }

    return (
        <>
            {message && (
                <Modal
                    onClose={() => setMessage(null)}
                    label="Подтверждение Email"
                    action="Ок"
                    text={message}
                />
            )}
            <SettingsContainer>
                <FadeIn>
                    <ClipBorder>
                        <SettingsInner onSubmit={formik.handleSubmit}>
                            <Field validate={validateEmail} title="Личные данные">
                                <BorderedControls
                                    dropRightPaddings
                                    button={userData.emailConfirmed && 'Подтвердить Email'}
                                    onClickButton={handleEmail}
                                    left={
                                        <FieldWrap>
                                            <Input
                                                validate={validateEmail}
                                                name="amountIn"
                                                // value={formik.values.amountIn}
                                                // onChange={onChangeInAmount}
                                                // onBlur={formik.handleBlur}
                                                // placeholder="example@gmail.com"
                                                placeholder={login}
                                                type="email"
                                                disabled={true}

                                            />
                                        </FieldWrap>
                                    }
                                />
                            </Field>
                            {/* <SubmitButton
                            $loading={loading}
                            type="button"
                            onClick={handleEmail}
                        >{loading ? <Spinner/> : 'Подтвердить почту'}</SubmitButton> */}
                            <PasswordField
                                label="Изменить пароль"
                                placeholder="Старый пароль"
                                name="current_password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <PasswordField
                                placeholder="Новый пароль"
                                name="new_password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <PasswordField
                                placeholder="Повторите пароль"
                                name="confirm_password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />

                            <SubmitButton
                                $loading={loading}
                                type="submit"
                            >{loading ? <Spinner /> : 'Изменить'}</SubmitButton>
                        </SettingsInner>
                    </ClipBorder>
                </FadeIn>
            </SettingsContainer>
        </>
    )
};

export default Settings;
