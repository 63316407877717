import styled from "styled-components";
import {
  Card,
  ListElement
} from "../../GlobalStyled";

const ContainerReserves = styled(Card)`
  padding: 70px 0 40px 0;
  display: flex;
  flex-direction: column;
  grid-gap: 24px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 100px 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    padding: 50px 0 150px 0;
  }
`;

const BorderedContent = styled.ul`
  padding: 0;
  margin: 0;
  
  li {
    display: flex;
    align-items: center;
    grid-gap: 63px;
    padding: 26px 38px 26px 64px;
    height: 100px;
    position: relative;

    @media screen and (min-width: 768px) and (max-width: 1023px) {
        grid-gap: 20px;
        padding: 24px 40px;
    }
    @media (max-width: ${props => props.theme.breakpoints.xs}) {
        grid-gap: 20px;
        padding: 24px 20px;
    }
    
    img {
      min-width: 48px;
      width: 48px;
      height: 48px;
      object-fit: cover;
    }
  }
`;

const ListReserveItem = styled(ListElement)`
  display: flex;
  align-items: center;
  grid-gap: 63px;
  padding: 26px 38px 26px 64px;
  height: 100px;

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    grid-gap: 20px;
    padding: 24px 40px;
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
      grid-gap: 20px;
      padding: 24px 20px;
  }

  img {
    min-width: 48px;
    width: 48px;
    height: 48px;
    object-fit: cover;
  }
`;

const BankPrice = styled.span`
  display: flex;
  flex-direction: column;
  span {
    &:first-child {
      font-size: 24px;
      font-weight: 500;
      @media (max-width: ${props => props.theme.breakpoints.sm}) {
        font-size: 18px;
      }
    }
    &:last-child {
      font-size: 16px;
      font-weight: 400;
      @media (max-width: ${props => props.theme.breakpoints.xs}) {
        font-size: 12px;
      }
    }
  }
`;

export {
  ContainerReserves,
  BorderedContent,
  BankPrice,
  ListReserveItem,
};
