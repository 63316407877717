import styled from "styled-components";
import {
  SubContainer,
  SubTitle
} from "../../GlobalStyled";

const Tabs = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 24px 0;

  ${SubTitle} {
    transition: all .3s ease-in;
    color: var(--gray-color);
  }

  .active {
    ${SubTitle} {
      color: var(--text-color);
    }
  }
  
  a {
    padding: 0;
  }
`;

const CardInner = styled.form`
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
  position: relative;
`;

const AuthContainer = styled(SubContainer)`
  padding: 50px 0 150px 0;
  margin: 0 auto;
`;

const RestoreTitle = styled.div`
  margin: 0 0 24px 0;
`;

const BottomLink = styled.span`
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  
  a {
    padding: 0;
    display: inline;
    font-size: 16px;
    font-weight: normal;
    text-decoration: underline;
    text-underline-offset: 5px;
  }
`;

export {
  Tabs,
  AuthContainer,
  CardInner,
  RestoreTitle,
  BottomLink,
};
