import {
  BackgroundLogo,
  ClipBorder,
  FadeIn,
  SubContainer,
  SubTitle
} from "../../GlobalStyled";
import {
  BankPrice,
  BorderedContent,
  ContainerReserves,
  ListReserveItem
} from "./styled";
import { BlurBackground } from "../Exchange/styled";
import {
  useEffect,
  useState
} from "react";
import { getReserves } from "../../api/request";

const numberSpacing = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const Reserves = () => {

  const [items, setItems] = useState([]);

  const fetch = async () => {
    try {
      const response = await getReserves();
      const result = response.data.map((item) => ({...item, amount: numberSpacing(item.amount)}))
      setItems(result);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <FadeIn>
      <SubContainer>
        <ContainerReserves>
          <SubTitle>Резервы валют</SubTitle>
          <ClipBorder>
            <BlurBackground/>
            <BackgroundLogo />
            <BorderedContent>
              {items.map((item) => (
                <ListReserveItem key={item.id}>
                  <img src={item.logoUrl} alt=""/>
                  <BankPrice>
                    <span>{item.amount} {item.currency}</span>
                    <span>{item.name}</span>
                  </BankPrice>
                </ListReserveItem>
              ))}
            </BorderedContent>
          </ClipBorder>
        </ContainerReserves>
      </SubContainer>
    </FadeIn>
  )
};

export default Reserves;
