import {
  ClipBorder,
  SubContainer
} from "../../GlobalStyled";
import {
  useCallback,
  useEffect,
  useState
} from "react";
import ContainerChange from "./styled";
import SecondStep from "./components/SecondStep/SecondStep";
import FirstStep from "./components/FirstStep/FirstStep";
import {
  getExchangeById,
  getPairs,
  getTokens
} from "../../api/request";
import { useRequest } from "../../hooks/useRequest";
import { useParams } from "react-router-dom";
import Modal from "../../components/Modal/Modal";

const Exchange = () => {
  const { id } = useParams();

  const { call } = useRequest(() => Promise.all([getTokens(), getPairs()]));

  const [step, setStep] = useState(0);
  const [tokens, setTokens] = useState([]);
  const [pairs, setPairs] = useState([]);

  const [savedFirstStep, setSavedFirstStep] = useState();

  const [message, setMessage] = useState(() => {
    return window.location.hash === '#emailVerifed' ? 'Регистрация аккаунта успешно завершена ' : null
  });

  const onNext = useCallback((values) => {
    localStorage.setItem(`order.${values.orderId}`, JSON.stringify({
      ...values,
      createTime: +new Date()
    }))

    window.location = `/confirm/${values.orderId}`
  }, []);

  const onPrev = useCallback(() => {
    window.location = `/`
  }, []);

  const loadData = async () => {
    try {
      const [tokens, pairs] = await call();
      setTokens(tokens.data);
      setPairs(pairs.data);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  //TODO fix second step page update
  useEffect(() => {
    const fetch = async () => {
      const rawData = localStorage.getItem(`order.${id}`);

      if (rawData) {
        const data = JSON.parse(rawData);
        setSavedFirstStep(data);
        setStep(1);

        console.log(data);
      }
    }

    if (id) {
      fetch();
    }
  }, [])

  return (
    <>
      {message && (
        <Modal
          onClose={() => {
            setMessage(null)
            window.location.hash = ''
          }}
          label="Подтверждение аккаунта"
          action="Ок"
          text={message}
        />
      )}

      <SubContainer>
        <ContainerChange>
          <ClipBorder>
            {step === 0 ? (
              <FirstStep
                tokens={tokens}
                pairs={pairs}
                onNext={onNext}
              />
            ) : (
              <SecondStep
                tokens={tokens}
                savedFirstStep={savedFirstStep}
                onSuccess={onPrev}
                onPrev={onPrev}
              />
            )}
          </ClipBorder>
        </ContainerChange>
      </SubContainer>
    </>
  )
};

export default Exchange;
