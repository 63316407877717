export const numberToTimer = (ms) => {
    const seconds = Math.max(0, ms/1000);

    const secs = Math.round(seconds % 60);
    const mins = Math.floor(seconds / 60);


    return `${zeroPad(mins, 2)}:${zeroPad(secs, 2)}`
}

function zeroPad(num, zeros) {
    return num.toString().padStart(zeros, "0");
}