import {useNavigate} from "react-router-dom";
import {
    BackButton,
    BackWrapper,
    BlurBackground,
    CardInner,
    FieldWrap,
    InputDisabled,
    OptionWrap,
    SubmitButton,
    Warning
} from "../../styled";
import BorderedControls from "../BorderedControls/BorderedControls";
import Field from "../Field/Field";
import Option from "../Select/Option";
import {
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import {useRequest} from "../../../../hooks/useRequest";
import {confirmExchange, getSettings} from "../../../../api/request";
import Spinner from "../../../../components/Spinner/Spinner";
import {BackgroundLogo, FadeIn} from "../../../../GlobalStyled";
import {useExchangeFormContext} from "../../../../providers/ExchangeFormContext";
import moment from "moment/moment";
import { numberToTimer } from "../../../../utils/time";
import { formatNumber } from "../../../../utils/format";

const warningText = 'Важно! Во избежание потери Ваших стредств, проверяйте номер кошелька при переводе';

const SecondStep = ({tokens, savedFirstStep, onSuccess, onPrev}) => {

    const {
        orderId,
        tokenOut,
        amountIn,
        amountOut,
        memo,
        dest,
        createTime
    } = savedFirstStep;

    const changeDest = window.innerWidth > 480
        ? dest.length > 32 ? removeNonAlphaNumeric(dest.slice(0, 24)) + '...' + removeNonAlphaNumeric(dest.slice(-4)) : removeNonAlphaNumeric(dest)
        : dest.length > 16 ? removeNonAlphaNumeric(dest.slice(0, 8)) + '...' + removeNonAlphaNumeric(dest.slice(-4)) : removeNonAlphaNumeric(dest)

    function removeNonAlphaNumeric (str) {
        return str.replace(/[^\p{L}\s\d]/gu, '')
    }

    const {onClear} = useExchangeFormContext();

    const navigate = useNavigate();

    const {call, loading, error} = useRequest(() => confirmExchange({orderId}));

    const refDest = useRef();
    const refAmount = useRef();

    const tokenOutValue = useMemo(() => {
        return tokens.find(item => item.id === tokenOut);
    }, [tokens, tokenOut]);

    const onCopies = (value) => () => {
        navigator.clipboard.writeText(value);
    };

    const onConfirm = async () => {
        try {
            await call();
            navigate(`/exchange-status/${orderId}`)
            onClear();
        } catch (err) {
            console.error(err);
        }
    };

    const [timeEnd, setTimeEnd] = useState(moment().add(15, 'minutes'));
    const [minutes, setMinutes] = useState(0);
    const [timeLeft, setTimeLeft] = useState(600);
  
    useEffect(() => {
        const timeout = setInterval(() => {
            setTimeLeft(timeEnd.diff())
        }, 250);
  
        return () => {
            clearInterval(timeout);
        }
    }, [timeEnd])

    useEffect(() => {
        const fetch = async () => {
            const settings = await getSettings()
            const minutes = settings.data.find((el) => el.code === 'auto_cancel_initial').value;
            setMinutes(minutes)
            setTimeEnd(moment(createTime).add(minutes, 'minutes'))
        }
        fetch();
    }, [])

    const isTimeEnded = useMemo(() => {
        return timeLeft <= 0;
    }, [timeLeft]);

    return (
        <CardInner>
            <BackgroundLogo />
            <BlurBackground/>
            <FadeIn delay={0.1}>
                <Field title="Сделайте перевод по реквизитам">
                    <BorderedControls
                        left={
                            <FieldWrap>
                                <InputDisabled ref={refDest} defaultValue={changeDest}/>
                            </FieldWrap>
                        }
                        copyButton={true}
                        onClickButton={onCopies(dest)}
                    />
                </Field>
            </FadeIn>
            <FadeIn delay={0.6}>
                <Field title="На сумму">
                    <BorderedControls
                        left={
                            <FieldWrap>
                                <InputDisabled ref={refAmount} defaultValue={amountIn}/>
                            </FieldWrap>
                        }
                        copyButton={true}
                        onClickButton={onCopies(amountIn)}
                    />
                </Field>
            </FadeIn>
            <FadeIn delay={0.8}>
                <Field title="Вы получите">
                    <BorderedControls
                        left={
                            <FieldWrap>
                                <InputDisabled
                                    value={formatNumber(amountOut, tokenOut.dimension)}
                                />
                            </FieldWrap>
                        }
                        right={
                            <OptionWrap>
                                <Option
                                    icon={tokenOutValue ? tokenOutValue.iconUrl : null}
                                    name={tokenOutValue ? tokenOutValue.name : ''}
                                />
                            </OptionWrap>
                        }
                    />
                </Field>
            </FadeIn>

            <FadeIn delay={1}>
                <Warning textCenter>
                    {error ? `Ошибка подтверждения. Проверьте введенные данные. ${error}` : warningText}
                </Warning>
            </FadeIn>

            <FadeIn delay={1}>
                <Warning textCenter>
                    {isTimeEnded ?
                        `Ваша заявка отозвана`
                    : (
                        `Оплатите заявку в течение - ${minutes} минут. Если в течение указанного времени Вы не совершите оплату, заявка автоматически отменится. `
                    )}
                </Warning>
            </FadeIn>

            <FadeIn delay={1.2}>
                {!isTimeEnded && (
                    <SubmitButton
                        type="button"
                        $loading={loading}
                        onClick={onConfirm}
                    >
                        {loading ? <Spinner/> : 'Я оплатил'}
                    </SubmitButton>
                )}

                <BackWrapper>
                    <BackButton
                        type="button"
                        onClick={onPrev}>Вернуться назад</BackButton>
                </BackWrapper>
            </FadeIn>
        </CardInner>
    )
};

export default SecondStep;
