import {
  ArrowDrop,
  IconWrap,
  SelectValue
} from "./styled";

const Option = ({ withControl = false, expanded, onClick, icon, name = 'USDT (BEP 20)' }) => {
  return (
    <SelectValue onClick={onClick}>
      <IconWrap>
        {icon && <img src={icon} alt=""/>}
      </IconWrap>

      <span>{name}</span>

      {withControl && (
        <ArrowDrop active={expanded}>
          <svg width="12" height="10" viewBox="0 0 12 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.13397 9.5C5.51887 10.1667 6.48113 10.1667 6.86603 9.5L11.1962 2C11.5811 1.33333 11.0999 0.5 10.3301 0.5H1.66987C0.900073 0.5 0.418948 1.33333 0.803848 2L5.13397 9.5Z" fill="currentColor"/>
          </svg>
        </ArrowDrop>
      )}
    </SelectValue>
  )
};

export default Option;
