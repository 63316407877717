import {
  EarthLeft,
  EarthMobile,
  EarthRight,
  ElipsePosition,
  ExoPosition,
  NexPosition,
  StarPosition,
  CometPosition
} from "./styled";
import Earth from "../../assets/images/earth.png";
import Earth2 from "../../assets/images/earth2.png";
import EarthCenter from "../../assets/images/earth-mobile.png";
import Elipsis from "../../assets/images/elipsis.png";
import Exo from "../../assets/images/exo.png";
import Star from "../../assets/images/star.png";
import Nex from "../../assets/images/nex.png";
import Comet from "../../assets/images/comet.png";
import { useState } from "react";
import { useEffect } from "react";
import { useLayoutEffect } from "react";

const BackgroundImages = () => {
  const [initComet, setCometInit] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => {
      setCometInit(true)
    }, 1000);
  }, [])

  return (
    <>
      <EarthLeft>
        <img src={Earth} alt=""/>
      </EarthLeft>
      <EarthRight>
        <img src={Earth2} alt=""/>
      </EarthRight>
      <EarthMobile>
        <img src={EarthCenter} alt=""/>
      </EarthMobile>
      <ElipsePosition>
        <img src={Elipsis} alt=""/>
      </ElipsePosition>
      <ExoPosition>
        <img src={Exo} alt=""/>
      </ExoPosition>
      <StarPosition>
        <img src={Star} alt=""/>
      </StarPosition>
      <NexPosition>
        <img src={Nex} alt=""/>
      </NexPosition>
      {initComet && (
        <CometPosition>
          <img src={Comet} alt=""/>
        </CometPosition>
      )}
    </>
  );
};

export default BackgroundImages;
