const MENU_LINKS = [
  {
    label: 'Обмен',
    link: '/',
    onAuth: false,
  },
  {
    label: 'FAQ',
    link: '/faq',
    onAuth: false,
  },
  {
    label: 'Резервы',
    link: '/reserves',
    onAuth: false,
  },
  {
    label: 'Контакты',
    link: '/contacts',
    onAuth: false,
  },
  {
    label: 'Аккаунт',
    link: '/account',
    onAuth: true,
  },
];

export default MENU_LINKS;
