import styled from "styled-components";
import {
  Card,
  ClipBorder,
  CommonButton
} from "../../GlobalStyled";

const ContainerStatus = styled(Card)`
  padding: 70px 0 40px 0;
  display: flex;
  flex-direction: column;
  grid-gap: 24px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 100px 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    padding: 50px 0 150px 0;
  }
`;

const StatusClipBorder = styled(ClipBorder)`
  padding: 40px 30px  
`

const Warning = styled.div`
  font-size: 18px;
  font-weight: bold;
  ${({success}) => success ? 'color: #61eb6d;' : '' }
  ${({process}) => process ? 'color: #1B8FFF;' : '' }
  ${({error}) => error ? 'color: var(--red-color);' : '' }
  padding: 20px;
  animation: appear .4s .2s ease-out backwards;
  text-align: ${({textCenter}) => textCenter ? 'center' : 'left'};

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    padding: 20px 10px;
    font-size: 16px;
  }

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    background: currentColor;
    clip-path: polygon(0 20.00px,20.00px 0,100% 0,100% calc(100% - 20.00px),calc(100% - 20.00px) 100%,0 100%,0 20.00px,2px  calc(20.00px + 0.83px),2px calc(100% - 2px),calc(100% - 20.00px - 0.83px) calc(100% - 2px),calc(100% - 2px) calc(100% - 20.00px - 0.83px),calc(100% - 2px) 2px,calc(20.00px + 0.83px) 2px,2px calc(20.00px + 0.83px));
  }

  @keyframes appear {
    from {
      opacity: 0;
      scale: 0;
    }
    to {
      opacity: 1;
      scale: 1;
    }
  }
`;

const OrderNumber = styled.div`
  color: var(--text-color);
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
`

const BackButton = styled(CommonButton)`
  color: var(--text-color);
  font-size: 24px;
  font-weight: 500;
  padding: 32px 0 0;
  &:hover {
    text-decoration: underline;
    text-underline-offset: 6px;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    padding: 18px 0 0;
  }
`;

const BackWrapper = styled.div`
  display: flex;
  justify-content: center;
`;


export {
  ContainerStatus,
  StatusClipBorder,
  BackButton,
  Warning,
  BackWrapper,
  OrderNumber
}
