import {
  AuthContainer,
  CardInner,
  RestoreTitle
} from "../../styled";
import {
  ClipBorder,
  FadeIn,
  SubTitle
} from "../../../../GlobalStyled";
import Field from "../../../Exchange/components/Field/Field";
import BorderedControls from "../../../Exchange/components/BorderedControls/BorderedControls";
import {
  BackButton,
  BackWrapper,
  FieldWrap,
  Input,
  SubmitButton
} from "../../../Exchange/styled";
import { useNavigate } from "react-router-dom";
import { restorePassword } from "../../../../api/request";
import { useFormik } from "formik";
import Spinner from "../../../../components/Spinner/Spinner";
import { useState } from "react";
import Modal from "../../../../components/Modal/Modal";

const Restore = () => {

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  const onSubmit = async ({ email }) => {
    try {
      setLoading(true);
      await restorePassword({ email });

      setMessage('Вам на почту отправлена ссылка для восстановления пароля')
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit,
  });

  return (
    <>
      {message && (
        <Modal
          onClose={() => setMessage(null)}
          label="Восстановление пароля"
          action="Ок"
          text={message}
        />
      )}

      <AuthContainer>
        <RestoreTitle>
          <SubTitle>Восстановление пароля</SubTitle>
        </RestoreTitle>
        <ClipBorder>
          <CardInner onSubmit={formik.handleSubmit}>
            <FadeIn delay={0.1}>
              <Field title="Email">
                <BorderedControls
                  dropRightPaddings
                  left={
                    <FieldWrap>
                      <Input
                        name="email"
                        // value={formik.values.amountIn}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="example@gmail.com"
                        type="email"
                      />
                    </FieldWrap>
                  }
                />
              </Field>
            </FadeIn>
            <FadeIn delay={0.3}>
              <SubmitButton
                type="submit"
              >{loading ? <Spinner /> : 'Сменить пароль'}</SubmitButton>
              <BackWrapper>
                <BackButton
                  type="button"
                  onClick={goBack}>Вернуться назад</BackButton>
              </BackWrapper>
            </FadeIn>
          </CardInner>
        </ClipBorder>
      </AuthContainer>
    </>
  )
};

export default Restore;
