import {
  List,
} from "./styled";
import { NavLink } from "react-router-dom";
import { SubTitle } from "../../../../GlobalStyled";

const Tabs = () => (
  <List>
    <li>
      <NavLink to="settings">
        <SubTitle>Настройки</SubTitle>
      </NavLink>
    </li>
    <li>
      <NavLink to="history">
        <SubTitle>
          <span>
            <span className="history">История обменов</span>
            <span className="history-xs">Обмены</span>
          </span>
        </SubTitle>
      </NavLink>
    </li>
    {/*<li>*/}
    {/*  <NavLink to="payments">*/}
    {/*    <SubTitle>Выплаты</SubTitle>*/}
    {/*  </NavLink>*/}
    {/*</li>*/}
  </List>
);

export default Tabs;
