import Tabs from "./components/Tabs";
import { Wrapper } from "./styled";
import { Outlet } from 'react-router-dom';

const Account = () => {

  return (
    <Wrapper>
      <Tabs/>
      <Outlet/>
    </Wrapper>
  )
};

export default Account;
