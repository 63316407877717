import StyledField, {
  Caption,
  Title
} from "./styled";

const Field = ({ title, children, caption }) => {

  return (
    <StyledField>
      <Title>{title}</Title>
      {children}
      {caption && <Caption>{caption}</Caption>}
    </StyledField>
  )
};

export default Field;
