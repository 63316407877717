import {
  ClipBorder,
  FadeIn,
} from "../../GlobalStyled";
import {
  ContainerFaq,
  FaqNavItem,
  NavSticky,
} from "./styled";
import { useState } from "react";
import Agreement from "./Agreement";
import FaqText from "./FaqText";

const ITEMS = [
  {
    key: 'agreement',
    label: 'Соглашение'
  },
  {
    key: 'faq',
    label: 'FAQ'
  },
];

const Faq = () => {

  const [active, setActive] = useState('agreement');

  const onChange = (value) => () => setActive(value);

  return (
    <ContainerFaq>
      <NavSticky>
        <FadeIn>
          <ClipBorder>
            <ul>
              {ITEMS.map((item, index) => (
                <FaqNavItem key={item.key} onClick={onChange(item.key)} active={item.key === active}>
                  <a>{`${index + 1}.`} {item.label}</a>
                </FaqNavItem>
              ))}
            </ul>
          </ClipBorder>
        </FadeIn>
      </NavSticky>
      {active === 'agreement' ? <Agreement/> :  <FaqText/>}
    </ContainerFaq>
  )
};

export default Faq;
