import HeaderStyled, {
  AuthButton,
  ButtonContainer,
  HeaderInner,
  SupportButton
} from "./styled/HeaderStyled";
import Menu from "./Menu";
import { Avatar } from "./styled/MenuStyled";
import MobileMenu from "./Menu/MobileMenu";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Logotype from "../../assets/images/emivn_logo.png";


const Header = ({ open, onOpen, onClose, auth}) => {

  const navigate = useNavigate();
  const goTo = (path) => () => {
    navigate(path);
    Cookies.remove("token", { sameSite: 'None', secure: true });
  }

  return (
    <HeaderStyled hidden={open}>
      <HeaderInner>
        <Avatar>
            <img src={Logotype} alt=""/>
        </Avatar>
        <Menu
            auth={auth}
        />
        <ButtonContainer>
          <SupportButton onClick={goTo('/contacts')}>Поддержка 24/7</SupportButton>
          <AuthButton onClick={goTo(auth ? '/auth/sign-out' : '/auth')}>{auth ? 'Выйти' : 'Войти'}</AuthButton>
        </ButtonContainer>
        <MobileMenu
          open={open}
          onOpen={onOpen}
          onClose={onClose}
          auth={auth}
        />
      </HeaderInner>
    </HeaderStyled>
  );
}

export default Header;
