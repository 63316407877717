import {
    BackgroundLogo,
    ClipBorder,
    FadeIn
} from "../../../../GlobalStyled";
import {
    BottomLink,
    CardInner
} from "../../styled";
import Field from "../../../Exchange/components/Field/Field";
import BorderedControls from "../../../Exchange/components/BorderedControls/BorderedControls";
import {
    FieldWrap,
    Input,
    SubmitButton
} from "../../../Exchange/styled";
import PasswordField from "../../../../components/PasswordField/PasswordField";
import {NavLink} from "react-router-dom";
import {useFormik} from "formik";
import {useState} from "react";
import Spinner from "../../../../components/Spinner/Spinner";
import {signUpUser} from "../../../../api/request";
import Modal from "../../../../components/Modal/Modal";

const SignUp = () => {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    const onSubmit = async ({login, password}) => {

        try {
            let err = validateEmail(login);
            if(err){
                alert(err);
                throw new Error(err);
            }
            setLoading(true);
            await signUpUser({login, password});

            setMessage('Вам на почту отправлена ссылка для завершения регистрации')
        } catch (err) {
            setError(err.response.data.errorMessage);
        } finally {
            setLoading(false);
        }
    };

    const validateEmail = (value) => {
        let error;
        if (!value) {
            error = 'Обязательное поле';
        } else if (
            !/^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(value)
        ) {
            error = 'Неверный email'
        }
        return error;
    };

    const formik = useFormik({
        initialValues: {
            login: '',
            password: '',
            passwordConfirm: '',
        },
        isValidating: true,
        validateOnBlur: false,
        validateOnChange: true,
        onSubmit,
    });



    return (
        <>
            {message && (
                <Modal
                    onClose={() => setMessage(null)}
                    label="Регистрация"
                    action="Ок"
                    text={message}
                />
            )}

            {error && (
                <Modal
                    onClose={() => setError(null)}
                    label="Ошибка регистрации"
                    action="Закрыть"
                    text={error}
                />
            )}
            <ClipBorder>
                <CardInner onSubmit={formik.handleSubmit}>
                    <BackgroundLogo />
                    <FadeIn delay={0.1}>
                        <Field validate={validateEmail} title="Email">
                            <BorderedControls
                                dropRightPaddings
                                left={
                                    <FieldWrap>
                                        <Input
                                            validate={validateEmail}
                                            name="login"
                                            value={formik.values.login}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            placeholder="example@gmail.com"
                                            type="email"
                                        />
                                    </FieldWrap>
                                }
                            />
                        </Field>
                    </FadeIn>
                    <FadeIn delay={0.3}>
                        <PasswordField
                            label="Пароль"
                            placeholder="Введите пароль"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </FadeIn>
                    <FadeIn delay={0.6}>
                        <PasswordField
                            label="Подтвердите пароль"
                            placeholder="Введите пароль"
                            name="passwordConfirm"
                            value={formik.values.passwordConfirm}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </FadeIn>
                    <FadeIn delay={0.8}>
                        <SubmitButton
                            $loading={loading}
                            type="submit"
                        >
                            {loading ? <Spinner/> : 'Зарегистрироваться'}
                        </SubmitButton>
                    </FadeIn>
                    <FadeIn delay={1}>
                        <BottomLink>
                            Уже зарегистрированы?    &nbsp; <NavLink to="/auth/sign-in">Войти</NavLink>
                        </BottomLink>
                    </FadeIn>
                </CardInner>
            </ClipBorder>
        </>
    )
};

export default SignUp;
