import styled, { css } from "styled-components";
import {
  ClipBorder,
  CommonButton
} from "../../../../GlobalStyled";

const Control = styled(ClipBorder)`
  width: 100%;
  &:before {
    background: ${({error}) => error ? 'var(--red-color)' : 'var(--accent-color)'};
    clip-path: polygon(0 10.00px,10.00px 0,100% 0,100% calc(100% - 10.00px),calc(100% - 10.00px) 100%,0 100%,0 10.00px,2px  calc(10.00px + 0.83px),2px calc(100% - 2px),calc(100% - 10.00px - 0.83px) calc(100% - 2px),calc(100% - 2px) calc(100% - 10.00px - 0.83px),calc(100% - 2px) 2px,calc(10.00px + 0.83px) 2px,2px calc(10.00px + 0.83px));
  }
`;

const Field = styled.div`
  flex: 1 1;
  position: relative;
  height: 60px;
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    flex: unset;
  }
`;

const Inner= styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    ${({stayFlex}) => stayFlex ? css`
      ${Field} {
        flex: 1 1;
      }
    ` : css`
        flex-direction: column;
        align-items: unset;
    `
}
  }
`;

const FieldRight = styled(Field)`
  &:last-child {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      height: 100%;
      width: 2px;
      transform: translateY(-50%);
      background: ${({error}) => error ? 'var(--red-color)' : 'var(--accent-color)'};
      @media (max-width: ${props => props.theme.breakpoints.xs}) {
        width: 100%;
        height: 2px;
        top: 0;
      }
    }
  }
`;

const ButtonWrap = styled(CommonButton)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--accent-color);
  font-size: 16px;
  padding: 0 30px 0 10px;
  font-weight: bold;
  transition: all .2s ease-in;

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: 0 10px;
    font-size: 14px;
  }
  
  &:active {
    color: #165593;
  }
`;

export default Control;
export {
  Field,
  FieldRight,
  Inner,
  ButtonWrap,
}
