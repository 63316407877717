import {
    ModalStyled,
    ModalWrapper,
    ModalLabel,
    ModalText,
    ModalButton
} from "./styled/ModalStyled";


const Modal = ({ onClose, label, text }) => {
    const handleClose = (e) => {
        if(e.target === e.currentTarget) {
            onClose();
        }
    }
    
    return (
        <ModalWrapper onClick={handleClose}>
            <ModalStyled>
                <ModalLabel>
                    {label}
                </ModalLabel>

                <ModalText>
                    {text}
                </ModalText>

                <ModalButton onClick={onClose}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.824 3.632L12 9.808L18.144 3.664C18.2797 3.51955 18.4432 3.40399 18.6247 3.32426C18.8061 3.24453 19.0018 3.20226 19.2 3.2C19.6243 3.2 20.0313 3.36857 20.3314 3.66863C20.6314 3.96869 20.8 4.37565 20.8 4.8C20.8037 4.99616 20.7673 5.19102 20.693 5.37261C20.6188 5.5542 20.5081 5.7187 20.368 5.856L14.144 12L20.368 18.224C20.6317 18.482 20.7863 18.8313 20.8 19.2C20.8 19.6243 20.6314 20.0313 20.3314 20.3314C20.0313 20.6314 19.6243 20.8 19.2 20.8C18.9961 20.8085 18.7926 20.7744 18.6026 20.7001C18.4125 20.6257 18.24 20.5126 18.096 20.368L12 14.192L5.84 20.352C5.70481 20.4916 5.5433 20.6031 5.3648 20.68C5.1863 20.7569 4.99434 20.7977 4.8 20.8C4.37565 20.8 3.96869 20.6314 3.66863 20.3314C3.36857 20.0313 3.2 19.6243 3.2 19.2C3.19627 19.0038 3.23266 18.809 3.30695 18.6274C3.38124 18.4458 3.49185 18.2813 3.632 18.144L9.856 12L3.632 5.776C3.36829 5.51801 3.21366 5.16866 3.2 4.8C3.2 4.37565 3.36857 3.96869 3.66863 3.66863C3.96869 3.36857 4.37565 3.2 4.8 3.2C5.184 3.2048 5.552 3.36 5.824 3.632Z" fill="white"/>
                    </svg>
                </ModalButton>
            </ModalStyled>
        </ModalWrapper>
    );
}

export default Modal;
