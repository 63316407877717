import {
  Cell,
  CellTitle,
  Header,
  HistoryContainer,
  PaymentsTable,
  Row
} from "../History/styled";
import { ClipBorder } from "../../../../GlobalStyled";

const Payments = () => {
  return (
    <HistoryContainer>
      <ClipBorder>
        <PaymentsTable>
          <Row cols={6}>
            <Header>Номер заявки</Header>
            <Header>Дата</Header>
            <Header>Статус</Header>
            <Header>Кошелек</Header>
            <Header>Сумма</Header>
            <Header>Сумма USD</Header>
          </Row>
          {new Array(12).fill('').map(() => (
            <Row cols={6}>
              <Cell>
                <CellTitle>Номер заявки</CellTitle>
                <span>№920342925</span>
              </Cell>
              <Cell>
                <CellTitle>Дата</CellTitle>
                <span>10.11.2022</span>
              </Cell>
              <Cell>
                <CellTitle>Статус</CellTitle>
                <span>Завершено</span>
              </Cell>
              <Cell>
                <CellTitle>Кошелек</CellTitle>
                <span>0x02390rhdf...5353</span>
              </Cell>
              <Cell>
                <CellTitle>Сумма</CellTitle>
                <span>23 489 RUB</span>
              </Cell>
              <Cell>
                <CellTitle>Сумма USD</CellTitle>
                <span>402 USD</span>
              </Cell>
            </Row>
          ))}
        </PaymentsTable>
      </ClipBorder>
    </HistoryContainer>
  )
};

export default Payments;
