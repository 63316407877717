import styled from "styled-components";
import {
  Card,
  ListElement
} from "../../GlobalStyled";

const ContainerContacts = styled(Card)`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  grid-gap: 24px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 100px 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    padding: 50px 0 150px 0;
  }
`;

const ContactItem = styled(ListElement)`
  display: flex;
  flex-direction: column;
  padding: 30px;
  
  p {
    margin: 16px 0 24px 0;
    font-weight: 400;
  }

  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 35px;

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      grid-gap: 16px;
    }
  }
  
  li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 4px;
    span {
      font-size: 14px;
      
      @media (max-width: ${props => props.theme.breakpoints.xs}) {
        font-size: 12px;
      }
    }
  }
  
  a {
    font-size: 18px;
    display: inline;
    padding: 0;
    color: var(--accent-color);
    font-weight: 400;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`

export {
  ContainerContacts,
  ContactItem,
};
