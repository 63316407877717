import {
  BackgroundLogo,
  ClipBorder,
  FadeIn,
  SubTitle
} from "../../GlobalStyled";
import {
  FaqContent,
  StyledBanks,
  TextItem
} from "./styled";
import {
  Cell,
  CellTitle,
  Header,
  Row,
  Table
} from "../Account/subpages/History/styled";

const FaqText = () => (
  <ClipBorder noAnimation>
    <BackgroundLogo />
    <FaqContent>
      <TextItem>
        <FadeIn>
          <li>
            <SubTitle>Как работает обменник?</SubTitle>
            <p>
              Обменник полностью автоматический. Вы можете совершать обмены 24/7/365.
              Выплата осуществляется мгновенно после зачисления Ваших средств на наш кошелек. Информация про статус заявки обновляется на странице оплаты и дублируется на почту.
            </p>
          </li>
        </FadeIn>
        <FadeIn delay={0.1}>
          <li>
            <SubTitle>Через сколько мне придет платеж?</SubTitle>
            <p>
              Платеж отправляется сразу же, как только от вас приходит оплата. Согласно практике регламента зачисления платежей, существует следующие сроки:
            </p>
            <StyledBanks>
              <ClipBorder>
                <Table>
                  <Row cols={3}>
                    <Header>Система</Header>
                    <Header>Банковский регламент</Header>
                    <Header>На практике</Header>
                  </Row>
                  <Row cols={3}>
                    <Cell>
                      <CellTitle>Система</CellTitle>
                      <span>VISA банков России</span>
                    </Cell>
                    <Cell>
                      <CellTitle>Банковский регламент</CellTitle>
                      <span style={{textAlign: 'right'}}>От 1 до 3 рабочих дней</span>
                    </Cell>
                    <Cell>
                      <CellTitle>На практике</CellTitle>
                      <span>1-60 минут</span>
                    </Cell>
                  </Row>
                  <Row cols={3}>
                    <Cell>
                      <CellTitle>Система</CellTitle>
                      <span>MC валютные</span>
                    </Cell>
                    <Cell>
                      <CellTitle>Банковский регламент</CellTitle>
                      <span style={{textAlign: 'right'}}>От 1 до 3 рабочих дней</span>
                    </Cell>
                    <Cell>
                      <CellTitle>На практике</CellTitle>
                      <span>1-60 минут</span>
                    </Cell>
                  </Row>
                </Table>
              </ClipBorder>
            </StyledBanks>
          </li>
        </FadeIn>
        <FadeIn delay={0.2}>
          <li>
            <SubTitle>Фиксируется ли курс обмена? </SubTitle>
            <p>
              Да, курс фиксируется в зависимости от направления обмена. Время фиксации указано до оплаты заявки.
            </p>
          </li>
        </FadeIn>
        <FadeIn delay={0.4}>
          <li>
            <SubTitle>Нужна ли верификация карты? </SubTitle>
            <p>
              Нет, верификация карты не требуется для обмена.
            </p>
          </li>
        </FadeIn>
        <FadeIn delay={0.6}>
          <li>
            <SubTitle>Если время фиксации курса вышло, что нужно делать?</SubTitle>
            <p>
              Заявка остается действующей и будет автоматически выполнена по факту получения подтверждений сети, согласно правил обмена: если курс упадет, вам придет письмо на почту с информацией для самостоятельного выполнения пересчета заявки по актуальному курсу или оформления возврата. Если Вами не будет принято решение в течении 12 часов, то заявка будет пересчитана автоматически по актуальному курсу. Если курс вырастет, то заявка будет выполнена автоматически по курсу на момент её создания.
            </p>
          </li>
        </FadeIn>
        <FadeIn delay={0.8}>
          <li>
            <SubTitle>Подтверждение сети и статус «Ожидает подтверждение»</SubTitle>
            <p>
              Согласно принципам работы блокчейнов, перевод криптомонет считается завершенным после определенного количества подтверждений сети. Количество подтверждений сети указаны перед каждым обменом согласно направлений валют. На скорость получения подтверждений влияет выставленная комиссия за перевод, поэтому рекомендуем вам всегда совершать переводы по рыночной комиссии, чтобы избежать задержки в проведении заявки.
            </p>
          </li>
        </FadeIn>
        <FadeIn delay={1}>
          <li>
            <SubTitle>Хочу отменить заявку</SubTitle>
            <p>
              В случае, если ваша заявка выполнена, к сожалению, отменить её невозможно. Если заявка оплачена, но платеж еще не отправлен на вашу карту - возврат такого платежа возможен за вычетом 10% от суммы заявки. Не оплаченные заявки отменяются автоматически при отсутствии оплаты.
            </p>
          </li>
        </FadeIn>
      </TextItem>
    </FaqContent>
  </ClipBorder>
)

export default FaqText;
