import axios from "axios";
import Cookies from "js-cookie";

export const api = axios.create({
    baseURL: "https://new-api.emivn-exchanger.io/api/",
    transformRequest: [function (data, headers) {
        headers['Content-Type'] = `application/json`;
        const token = Cookies.get("token");
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
        return data;
    }]
})

// const api = ky.create({
//     prefixUrl: "https://new-api.emivn-exchanger.io/api/",
//     // prefixUrl: "http://localhost:8000/api/",
//     hooks: {
//         beforeRequest: [
//             (request) => {
//                 const token = Cookies.get("token");
//                 if (token) {
//                     request.headers.set("Authorization", `Bearer ${token}`);
//                 }
//             },
//         ],
//     },
// });

const getTokens = () => api.get("v1/tokens").then((res) => res.data);
const getPairs = () => api.get("v1/exchange-pairs").then((res) => res.data);
const getReserves = () => api.get("v1/reserves").then((res) => res.data);
const getExchangeById = (id) => api.get(`v1/exchanges/${id}`).then((res) => res.data);
const getExchangeStatusById = (id) => api.get(`v1/exchanges/status/${id}`).then((res) => res.data);
const initExchange = (body) => api.post("v1/exchanges/init", JSON.stringify(body)).then((res) => res.data);
const availableOperators = (body) => api.post("v1/exchanges/available-operators", JSON.stringify(body)).then((res) => res.data);
const confirmExchange = (body) => api.post("v1/exchanges/confirm", JSON.stringify(body)).then((res) => res.data);
const signUpUser = (body) => api.post("v1/auth/signup", JSON.stringify(body)).then((res) => res.data);
const getHistory = () => api.get("v1/user/history").then((res) => res.data);
const authUser = (body) => api.post("v1/auth/signin", JSON.stringify(body)).then((res) => res.data);
const getSettings = (body) => api.get("v1/settings").then((res) => res.data);

const authConfirm = (body) => api.post("v1/auth/confirm", JSON.stringify(body)).then((res) => res.data);
const repeatConfirm = (body) => api.post("v1/auth/repeatConfirm", JSON.stringify(body)).then((res) => res.data);
const getUser = () => api.get("v1/user/me").then((res) => res.data);
const updateUser = (body) => api.put("v1/user/me", JSON.stringify(body)).then((res) => res.data);
const restorePassword = (body) => api.post("v1/auth/recoveryPassword", JSON.stringify(body)).then((res) => res.data);

export {
    getHistory,
    getTokens,
    getPairs,
    getReserves,
    initExchange,
    getExchangeById,
    getExchangeStatusById,
    confirmExchange,
    signUpUser,
    authUser,
    getUser,
    updateUser,
    restorePassword,
    authConfirm,
    getSettings,
    repeatConfirm,
    availableOperators
};
