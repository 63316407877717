import styled from "styled-components";
import {
  Card,
  Container,
  ListElement
} from "../../GlobalStyled";

const ContainerFaq = styled(Container)`
  position: relative;
  padding: 40px 0;
  display: flex;
  align-items: flex-start;
  grid-gap: 20px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 100px 0;
    flex-direction: column;
  }

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    padding: 50px 0 150px 0;
  }
`;

const FaqNavItem = styled(ListElement)`
  a {
    display: inline-block;
    width: 100%;
    padding: 30px;
    font-size: 24px;
    font-weight: 500;
    cursor: pointer;
    @media (max-width: ${props => props.theme.breakpoints.xs}) {
      font-size: 18px;
    }
    &:hover {
      color: var(--accent-color);
    }
    color: ${(props) => props.active && 'var(--accent-color)'}
  }
`;

const FaqContent = styled(Card)`
  padding: 30px;
`;

const NavSticky = styled.div`
  position: sticky;
  top: 5px;
  width: 100%;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    position: unset;
  }
`;

const TextItem = styled.ul`
  display: flex;
  flex-direction: column;
  grid-gap: 48px;
  
  p {
    font-weight: 400;
    line-height: 22.4px;
    margin: 16px 0 0 0;
  }
`;

const AgreeSubUl = styled.ul`
  padding: 0 0 0 20px;
  li {
    list-style-type: disc;
    margin-bottom: 20px;
    &:first-child {
      margin-top: 16px;
    }
  }
`;

const StyledBanks = styled.div`
  margin-top: 32px;
`;

export {
  ContainerFaq,
  FaqContent,
  FaqNavItem,
  NavSticky,
  TextItem,
  AgreeSubUl,
  StyledBanks,
};
