import styled from "styled-components";
import { SubContainer } from "../../GlobalStyled";

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 40px 0 0 0;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 100px 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    padding: 50px 0 150px 0;
  }
`;

const SettingsContainer = styled(SubContainer)`
  margin: 0;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`;

const SettingsInner = styled.form`
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  grid-gap: 32px;

  @media screen and (max-width: 767px) {
    padding: 40px 20px;
  }
`;

export {
  Wrapper,
  SettingsContainer,
  SettingsInner,
}
