import BorderedControls from "../../pages/Exchange/components/BorderedControls/BorderedControls";
import {
  FieldWrap,
  Input
} from "../../pages/Exchange/styled";
import Field from "../../pages/Exchange/components/Field/Field";
import { useState } from "react";

const PasswordField = ({ label, ...inputProps }) => {

  const [type, setType] = useState('password');

  const toggle = () => setType((prev) => prev === 'password' ? 'text' : 'password');

  const text = type === 'password' ? 'Показать' : 'Скрыть';

  if (label) {
    return (
      <Field title={label}>
        <BorderedControls
          left={
            <FieldWrap>
              <Input
                type={type}
                {...inputProps}
              />
            </FieldWrap>
          }
          button={text}
          onClickButton={toggle}
        />
      </Field>
    );
  }

  return (
    <BorderedControls
      left={
        <FieldWrap>
          <Input
            type={type}
            {...inputProps}
          />
        </FieldWrap>
      }
      button={text}
      onClickButton={toggle}
    />
  );
};

export default PasswordField;
