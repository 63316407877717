import {
  BackgroundLogo,
  ClipBorder,
  FadeIn,
  SubContainer,
  SubTitle
} from "../../GlobalStyled";
import {
  ContactItem,
  ContainerContacts
} from "./styled";
import { NavLink } from "react-router-dom";
import { BlurBackground } from "../Exchange/styled";

const Contacts = () => {

  return (
    <FadeIn>
      <SubContainer>
        <ContainerContacts>
          <ClipBorder>
            <BackgroundLogo />
            <BlurBackground/>
            <ul>
              <ContactItem>
                <SubTitle>Техническая поддержка</SubTitle>
                <p>По техническим вопросам, сложностям с обменом и вопросам работы сервиса:</p>
                <ul>
                  <li>
                    <span>Электронная почта</span>
                    <a href="mailto:info@emivn-exchanger.io">info@emivn-exchanger.io</a>
                  </li>
                  <li>
                    <span>Telegram</span>
                    <a href="https://t.me/EMIVN_EX_BOT">@EMIVN_EX_BOT</a>
                  </li>
                </ul>
              </ContactItem>
              <ContactItem>
                <SubTitle>VIP-клиентам</SubTitle>
                <p>Если Вы хотите узнать об уникальных условиях для VIP-клиентов EMIVN,
                  напишите нам. Наши менеджеры ответят на все интересующие Вас вопросы и подробно расскажут о
                  программе лояльности.</p>
                <ul>
                  <li>
                    <span>VIP-почта</span>
                    <a href="mailto:info@emivn-exchanger.io">info@emivn-exchanger.io</a>
                  </li>
                  <li>
                    <span>Telegram</span>
                    <a href="https://t.me/EMIVN_EX_BOT">@EMIVN_EX_BOT</a>
                  </li>
                </ul>
              </ContactItem>
              <ContactItem>
                <SubTitle>Частые вопросы и информация</SubTitle>
                <p>
                  На нашем сайте Вы можете ознакомиться с популярными вопросами и ответами
                  {' '}<NavLink to="/faq">в разделе FAQ</NavLink>.
                </p>
              </ContactItem>
            </ul>
          </ClipBorder>
        </ContainerContacts>
      </SubContainer>
    </FadeIn>
  )
};

export default Contacts;
