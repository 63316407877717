import {
  NavLink,
  Outlet
} from 'react-router-dom';
import {
  AuthContainer,
  Tabs
} from "./styled";
import { SubTitle } from "../../GlobalStyled";

const Auth = () => {

  return (
    <AuthContainer>
      <Tabs>
        <NavLink to='sign-in'>
          <SubTitle>Войти</SubTitle>
        </NavLink>
        <NavLink to='sign-up'>
          <SubTitle>Регистрация</SubTitle>
        </NavLink>
      </Tabs>

      <Outlet/>
    </AuthContainer>
  )
};

export default Auth;
