import styled from "styled-components";
import { CommonButton } from "../../../GlobalStyled";

const HeaderStyled = styled.header`
  --gradient: linear-gradient(153.42deg, rgba(27, 143, 255, 0) 0%, rgba(27, 143, 255, 0.842105) 42.71%, rgba(27, 143, 255, 0) 100%),
  linear-gradient(0deg, rgba(10, 10, 10, 0.13), rgba(10, 10, 10, 0.13));

  position: relative; 
  height: 100px;
  width: 100%;
  transition: opacity .6s ease-in;
  opacity: ${({ hidden }) => hidden ? 0 : 1};
  margin: 0 auto;
  
  &:before {
    content: "";
    position: absolute;
    inset: 0;
    background: var(--gradient);
    background-size: 400% 400%;
    clip-path: polygon(0 20.00px,20.00px 0,100% 0,100% calc(100% - 20.00px),calc(100% - 20.00px) 100%,0 100%,0 20.00px,4px  calc(20.00px + 1.66px),4px calc(100% - 4px),calc(100% - 20.00px - 1.66px) calc(100% - 4px),calc(100% - 4px) calc(100% - 20.00px - 1.66px),calc(100% - 4px) 4px,calc(20.00px + 1.66px) 4px,4px calc(20.00px + 1.66px));

    animation: gradient 5s ease infinite;
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      animation: none;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    height: 80px;
  }
`;

const HeaderInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  padding: 10px 0;
  grid-gap: 10px;
  margin: 0 0 0 auto;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: none;
  }
`;

const SupportButton = styled(CommonButton)`
  color: var(--accent-color);
  width: 320px;
  height: 100%;
  font-size: 24px;

  &:before {
    overflow: hidden;
    content: "";
    position: absolute;
    inset: 0;
    background: var(--accent-color);
    clip-path: polygon(0 20.00px,20.00px 0,100% 0,100% calc(100% - 20.00px),calc(100% - 20.00px) 100%,0 100%,0 20.00px,4px  calc(20.00px + 1.66px),4px calc(100% - 4px),calc(100% - 20.00px - 1.66px) calc(100% - 4px),calc(100% - 4px) calc(100% - 20.00px - 1.66px),calc(100% - 4px) 4px,calc(20.00px + 1.66px) 4px,4px calc(20.00px + 1.66px));
  }
  
  &:hover {
    color: var(--text-color);
    background: var(--accent-color);
    clip-path: polygon(0 20.00px,20.00px 0,100% 0,100% calc(100% - 20.00px),calc(100% - 20.00px) 100%,0 100%);
  }

  @media (max-width: 1100px) {
    display: none;
  }
`;

const AuthButton = styled(CommonButton)`
  width: 200px;
  height: 100%;
  font-size: 24px;
  margin: 0 10px 0 0;
  color: var(--text-color);
  
  &:before {
    overflow: hidden;
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    background: linear-gradient(180deg, rgba(27, 143, 255, 0) 0%, #1B8FFF 100%);
    clip-path: polygon(0 20.00px,20.00px 0,100% 0,100% calc(100% - 20.00px),calc(100% - 20.00px) 100%,0 100%);
  }
  &:after {
    overflow: hidden;
    content: "";
    position: absolute;
    inset: 0;
    background: var(--accent-color);
    clip-path: polygon(0 20.00px,20.00px 0,100% 0,100% calc(100% - 20.00px),calc(100% - 20.00px) 100%,0 100%,0 20.00px,4px  calc(20.00px + 1.66px),4px calc(100% - 4px),calc(100% - 20.00px - 1.66px) calc(100% - 4px),calc(100% - 4px) calc(100% - 20.00px - 1.66px),calc(100% - 4px) 4px,calc(20.00px + 1.66px) 4px,4px calc(20.00px + 1.66px));
  }
`;


export { HeaderInner, SupportButton, ButtonContainer, AuthButton };
export default HeaderStyled;

