import styled from "styled-components";

const HistoryContainer = styled.section`
  width: 100%;
`;

const Cell = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  grid-gap: 4px;
  word-break: break-word;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
`;

const PaymentsTable = styled.div`
  display: flex;
  flex-direction: column;
  ${Cell} {
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      &:first-child {
        grid-row: unset;
      }
    }
  }
`;


const CellTitle = styled.span`
  display: none;
  font-size: 14px;
  font-weight: bold;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: block;
  }
`;

const Header = styled(Cell)`
  font-size: 14px;
  font-weight: bold;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: none;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(${({cols}) => cols || 5}, 1fr);
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
    background: linear-gradient(90deg,rgba(27,143,255,0) 0%,#1B8FFF 44.79%,rgba(27,143,255,0) 100%);
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: flex;
    flex-direction: column;
  }
`;

export {
  HistoryContainer,
  Table,
  PaymentsTable,
  Header,
  Cell,
  CellTitle,
  Row,
}
