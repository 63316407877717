import styled from "styled-components";


const StyledField = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
`;

const Title = styled.span`
  font-size: 24px;
`;

const Caption = styled.span`
  font-size: 13px;
  text-transform: uppercase;
  color: var(--grey-color);
  font-weight: bold;
  display: inline-block;
  margin: -8px 0 0 0;
  height: 13px;
`;

export default StyledField;
export {
  Title,
  Caption,
};
