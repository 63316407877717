import {
    BackgroundLogo,
    ClipBorder,
    FadeIn
} from "../../../../GlobalStyled";
import BorderedControls from "../../../Exchange/components/BorderedControls/BorderedControls";
import {
    FieldWrap,
    Input,
    SubmitButton
} from "../../../Exchange/styled";
import Field from "../../../Exchange/components/Field/Field";
import {
    BottomLink,
    CardInner
} from "../../styled";
import PasswordField from "../../../../components/PasswordField/PasswordField";
import { NavLink, useNavigate, useLocation, useParams } from "react-router-dom";
import { PasswordWrap } from "./styled";
import { useState } from "react";
import { authUser, repeatConfirm } from "../../../../api/request";
import { useFormik } from "formik";
import Spinner from "../../../../components/Spinner/Spinner";
import Cookies from 'js-cookie'
import Modal from "../../../../components/Modal/Modal";

const Login = () => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    if (location.pathname === '/auth/sign-out') {
        Cookies.remove("token");
        navigate('/');
    }

    const onSubmit = async ({ login, password }) => {
        try {
            setLoading(true);
            await authUser({ login, password }).then((body) => {
                Cookies.set("token", body.data.token, { sameSite: 'None', secure: true })
                navigate('/account/history');
            }).catch(async (err) => {
                const errorMessage = err.response.data;
                setError(errorMessage.errorMessage)
            });
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const formik = useFormik({
        initialValues: {
            login: '',
            password: '',
        },
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit,
    });

    const [message, setMessage] = useState(null);

    const handleEmail = () => {
        setLoading(true);
        setLoading(false);

        const fetch = async () => {
            const res = await repeatConfirm({
                email: formik.values.login
            })
                .then((res) => {
                    setMessage("Ссылка для подтверждения отправлена на ваш Email");
                })
                .catch((err) => {
                    setMessage('Что-то пошло не так')
                });
        }
        fetch();
    }

    return (
        <>

            {message && (
                <Modal
                    onClose={() => setMessage(null)}
                    label="Подтверждение Email"
                    action="Ок"
                    text={message}
                />
            )}  
            {error && (
                <Modal
                    onClose={() => setError(null)}
                    label="Ошибка входа"
                    action="Закрыть"
                    text={error}
                />
            )}

            <ClipBorder>
                <CardInner onSubmit={formik.handleSubmit}>
                    <BackgroundLogo />
                    <FadeIn delay={0.1}>
                        <Field title="Email">
                            <BorderedControls
                                dropRightPaddings
                                button={'Подтвердить Email'}
                                onClickButton={handleEmail}
                                left={
                                    <FieldWrap>
                                        <Input
                                            name="login"
                                            value={formik.values.login}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            placeholder="example@gmail.com"
                                            type="email"
                                        />
                                    </FieldWrap>
                                }
                            />
                        </Field>
                    </FadeIn>
                    <FadeIn delay={0.3}>
                        <PasswordWrap>
                            <PasswordField
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                label="Пароль"
                                placeholder="Введите пароль"
                                name="password"
                            />
                            <NavLink to="/restore">Забыли пароль?</NavLink>
                        </PasswordWrap>
                    </FadeIn>
                    <FadeIn delay={0.6}>
                        <SubmitButton
                            $loading={loading}
                            type="submit"
                        >{loading ? <Spinner /> : 'Войти'}</SubmitButton>
                    </FadeIn>
                    <FadeIn delay={0.8}>
                        <BottomLink>
                            Еще нет аккаунта?    &nbsp; <NavLink to="/auth/sign-up">Зарегистрироваться</NavLink>
                        </BottomLink>
                    </FadeIn>
                </CardInner>
            </ClipBorder>
        </>
    )
};

export default Login;
