import {
  BackgroundLogo,
  ClipBorder,
  SubTitle
} from "../../GlobalStyled";
import {
  AgreeSubUl,
  FaqContent,
  TextItem
} from "./styled";
import { BlurBackground } from "../Exchange/styled";

const Agreement = () => (
  <ClipBorder noAnimation>
    <BlurBackground/>
    <BackgroundLogo />
    <FaqContent>
      <TextItem>
        <li>
          <SubTitle>1. Общие положения</SubTitle>
          <p>
            Настоящее соглашение (далее по тексту Соглашение) описывает правила и условия, на основании которых предоставляются услуги p2p-платформы переводов электронных валют различных платежных систем EMIVN, и является официальной письменной публичной офертой, адресованной физическим лицам (далее Пользователь) заключить Соглашение о предоставлении услуг сервисом EMIVN на изложенных ниже условиях. Перед тем как воспользоваться услугами сервиса EMIVN, Пользователь обязан ознакомиться в полном объеме с условиями Соглашения о предоставлении услуг сервисом EMIVN. Использование услуг сервиса EMIVN возможно, только если Пользователь принимает все условия Соглашения. Действующая версия Соглашения расположена для публичного доступа на интернет-сайте сервиса EMIVN (https://emivn-exchanger.io).
          </p>
        </li>
        <li>
          <SubTitle>2. Термины и определения, используемые в Соглашении</SubTitle>

          <p>
            Сервис EMIVN — название p2p-платформы переводов электронных валют различных платежных систем. Сервис EMIVN предлагает свои услуги с помощью специального программного интерфейса для всех Пользователей.
          </p>
          <p>
            Интернет-сайт Сервиса – https://emivn-exchanger.io
          </p>
          <p>
            Пользователь — любое физическое лицо, которое желает воспользоваться услугами сервиса EMIVN и принимает данное Соглашения в соответствии с его условиями.
          </p>
          <p>
            Платежная система — программный продукт, созданный третьей стороной, представляющий собой механизм реализации учета денежных и/или иных обязательств, оплату товаров и услуг в сети Интернет, а также организацию взаиморасчетов между своими пользователями.
          </p>
          <p>
            Электронная валюта – денежное и/или иное обязательство между разработчиком данной валюты и ее пользователем, выраженное цифровым способом.
          </p>
          <p>
            Платеж/операция — перевод электронной и/или иной валюты от плательщика к получателю.
          </p>
          <p>
            Заявка — информация, поданная Пользователем  с использованием средств Сервиса в электронном виде, свидетельствующая о его намерениях воспользоваться услугами Сервиса на условиях, предложенных Сервисом и указанных в параметрах заявки.
          </p>
          <p>
            Исходная валюта – валюта, которую Пользователь желает обменять.
          </p>
          <p>
            Исходный счет – номер кошелька или любое другое обозначения счета Пользователя в Платежной системе, с которого была отправлена Исходная валюта.
          </p>
          <p>
            Валюта получения – валюта, которую Пользователь получает в результате обмена Исходной валюты.
          </p>
          <p>
            Счет получения – номер кошелька или любое другое обозначения счета Пользователя в Платежной системе, на который будет отправлена Валюта получения.
          </p>
          <p>
            Резерв валюты - имеющийся в распоряжении Сервиса EMIVN, на момент создания Заявки, объем определенной Электронной валюты.
          </p>
          <p>
            Обмен валюты - обмен электронной валюты одной платежной системы на электронную валюту другой платежной системы.
          </p>
          <p>
            Курс — стоимостное соотношение двух электронных валют при их обмене.
          </p>
          <p>
            Нарушение работы Сервиса - вмешательство в программную или аппаратную части Сервиса; искажение параметров, передаваемых Сервису; создание заявок без их фактической оплаты, за определенный системой временной отрезок.
          </p>
        </li>
        <li>
          <SubTitle>3. Предмет соглашения</SubTitle>
          <p>
            3.1. Предметом данного соглашения являются услуги по обмену электронных валют посредством р2р-платежей между физическими лицами.
          </p>
          <p>
            3.2. Сервис предлагает свои услуги всем Пользователям и не осуществляет надзора за операциями Пользователя в любой из Платежных систем.
          </p>
          <p>
            3.3. Любая завершенная операция по обмену электронной валюты не может быть отменена Пользователем после ее завершения, т.е. после получения Пользователем причитающегося ему, по ранее акцептованным условиям сделки, денежных средств, выраженных в электронном или фиатном виде.
          </p>
        </li>
        <li>
          <SubTitle>4.Права и обязанности сторон</SubTitle>
          <p>
            4.1. Сервис предоставляет свои услуги на условиях «как есть», как они описаны на страницах сайта Сервиса, и не предлагает никаких дополнительных гарантий.
          </p>
          <p>
            4.2. Заботясь о качестве оказываемых Пользователям услуг, Сервис EMIVN обязуется совершать все действия в рамках настоящего Соглашения.
          </p>
          <p>
            4.3. Сервис гарантирует исполнение обязательств перед Пользователем только в пределах сумм, вверенных Сервису Пользователем для осуществления операции обмена.
          </p>
          <p>
            4.4. Сервис не гарантирует, что его услуги будут доступны круглосуточно. Сервис не несет никакой ответственности за убытки, недополученную прибыль и иные издержки Пользователя, возникшие в результате невозможности получения доступа к сайту и услугам Сервиса.
          </p>
          <p>
            4.5. Сервис не несет никакой ответственности за убытки, недополученную прибыль и иные издержки Пользователя, явившиеся результатом задержек, ошибок или сбоев в проведении банковских платежей или электронных переводов. А также за недополученную Пользователем прибыль и иные издержки, явившиеся результатом ошибочных ожиданий Пользователя в отношении тарифных ставок Сервиса, прибыльности транзакций и иных субъективных факторов.
          </p>
          <p>
            4.6. В случае исполненной заявки Сервис не несет ответственности и не возмещает убытки, если Пользователь неверно указал реквизиты при оформлении заявки. В этом случае Сервис не обязуется проводить действия по возвращению таких средств.
          </p>
          <p>
            4.7. При нарушении Пользователем данного Соглашения Сервис имеет право расторгнуть договор путем отказа в исполнении заявки и возврата поступивших средств на реквизиты отправителя либо иные реквизиты, предоставленные пользователем путем отправки письма непосредственно с адреса электронной почты, указанного в заявке. При невозможности либо отказе (равно как и игнорировании) в просьбе Сервиса уточнить реквизиты вышеуказанным способом, Сервис оставляет за собой право вернуть поступившие средства на источник поступления.
          </p>
          <p>
            4.8. Сервис вправе приостановить или отменить выполняющуюся операцию, если Пользователь нарушает условия данного соглашения, и вернуть внесенные Пользователем средства на реквизиты пользователя.
          </p>
          <p>
            4.9. Сервис EMIVN не проверяет правомочность и законность владения Пользователем электронными валютами и/или денежными средствами, участвующими в конкретной Операции.
          </p>
          <p>
            4.10. Сервис в праве ограничить доступ Пользователя к услугам Сервиса при нарушении Пользователем данного соглашения, либо при подозрении Пользователя в попытке нарушить работу Сервиса (см. пункт “Термины”).
          </p>
          <p>
            4.11. Сервис имеет право возложить издержки, связанные с возвратом поступивших от Пользователя средств, в случаях, указанных в ПП. (4.8; 4.12; 4.13).
          </p>
          <p>
            4.12. Сервис имеет право, в случае поступления от Пользователя к Сервису средств в сумме, отличающейся от указанной в заявке, рассматривать это как распоряжение Пользователя сделать перерасчет и автоматически выполнить заявку согласно фактически поступившей сумме без дополнительного согласования с Пользователем.
          </p>
          <p>
            4.13. Сервис имеет право в одностороннем порядке расторгнуть соглашение с Пользователем и вернуть средства, если оплата от пользователя поступила в срок, превышающий время, отведенное на оплату заявки.
          </p>
          <p>
            4.14. Сервис, используя технически сложный программный продукт, допускает возможность технического сбоя и оставляет за собой право в таких случаях отказать Пользователю в исполнении условий договора с последующим возвратом средств на реквизиты Пользователя в полном объеме.
          </p>
          <p>
            4.15. Сервис имеет право в операциях обмена, связанных с наличными средствами, не применять условия партнерской программы.
          </p>
          <p>
            4.16. Сервис EMIVN не является стороной соглашения между Платежной системой/Биржей и Клиентом платежной системы/Биржи и ни в коем случае не несет ответственности за действия Платежной системы/Биржи, и ее Клиента.
          </p>
          <p>
            4.17. Воспользовавшись услугами Сервиса EMIVN, Пользователь подтверждает, что законно владеет и распоряжается денежными средствами и электронной валютой, участвующими в соответствующем Платеже.
          </p>
          <p>
            4.18. Пользователь должен учитывать, что единственный приемлемый для сервиса способ изменить реквизиты в созданной заявке - электронное письмо, отправленное с почты, указанной Пользователем в заявке.
          </p>
          <p>
            4.19. Пользователь обязуется самостоятельно исчислять и уплачивать все налоги, требуемые согласно налоговому законодательству места нахождения Пользователя.
          </p>
          <p>
            4.20. Переводя средства для обмена на реквизиты, предоставленные Сервисом в заявке, Пользователь подтверждает свое согласие с данным Соглашением.
          </p>
          <p>
            4.21. Пользователь может выразить свою благодарность за работу Сервиса, в любой удобной для Пользователя форме.
          </p>
          <p>
            4.22. Пользователь обязуется не использовать Сервис для проведения мошеннических и незаконных операций.
          </p>
          <p>
            4.23. Пользователь обязуется не нарушать работу Сервиса.
          </p>
          <p>
            4.24. Пользователь обязуется самостоятельно изучить и понимать условия используемой им Платежной системы/Биржи/Банка, которые регулируются соответствующими соглашениями, правилами и условиями между ними и клиентом.
          </p>
          <p>
            4.25. Сервис вправе не предоставлять Пользователю информацию по заявке, срок давности которой составляет более 30 календарных дней.
          </p>
          <p>
            4.26. Сервис вправе не рассматривать любые претензии по заявке, срок давности по которой составил более 30 календарных дней с момента ее создания Пользователем. Сервис не несет ответственности за возможные убытки Пользователя по такой заявке.
          </p>
          <p>
            4.27. Транзакции проверяются сервисом Amlbot, в случае если транзакция будет идентифицирована как высокорискованная (более 50%) Сервис может приостановить заявку для проведения проверки.
          </p>
        </li>
        <li>
          <SubTitle>5. Гарантии и ответственность сторон</SubTitle>
          <p>
            5.1. Сервис EMIVN не отвечает за убытки Пользователя, возникшие в результате неправомерных действий третьих лиц.
          </p>
          <p>
            5.2. Сервис гарантирует исполнение условий данного соглашения.
          </p>
          <p>
            5.3. В заявках на обмен, в которых Исходная валюта - криптовалюта, Сервис гарантирует фиксацию курса после первого подтверждения транзакции в сети. До тех пор, пока не получено подтверждение, заявка может быть пересчитана согласно биржевому курсу, при его изменении более, чем на 0.5%. В случае если заявка оплачена в объеме, отличающемся от заявленного, курс обмена по такой заявке будет пересчитан согласно биржевому курсу на момент поступления оплаты. Если оплата по заявке поступила по истечении таймера, отведенного для оплаты, курс обмена по такой заявке будет пересчитан согласно биржевому курсу на момент поступления оплаты.
          </p>
          <p>
            5.4. Пользователь обязан предоставлять достоверные сведения при заполнении Заявки. В случае если Пользователь не указал или неверно указал данные, Сервис EMIVN не несет ответственности за убытки Пользователя, понесенные в результате допущенной ошибки.
          </p>
          <p>
            5.5. Сервис вправе в любое время внести изменения или дополнения в данное Соглашение, они приобретают силу и начинают действовать после их публикации.
          </p>
        </li>
        <li>
          <SubTitle>6. Форс-мажор</SubTitle>
          <p>
            6.1. Ни Пользователь, ни Сервис не будут ответственны друг перед другом за задержки или неисполнение своих обязательств, являющихся результатом возникновения обстоятельств непреодолимой силы, включая стихийные бедствия, пожар, наводнение, террористические акты, смены власти, гражданские волнения, а также не функционирования Платежных систем, систем энергоснабжения, сетей связи и поставщиков Интернет-услуг.
          </p>
        </li>
        <li>
          <SubTitle>Условия некоторых Платежных систем:</SubTitle>
          <AgreeSubUl>
            <li>
              Платежные системы и/или финансовые учреждения несут исключительную ответственность за средства, вверенные им Пользователем. Сервис не может являться стороной в соглашении между Платежной системой и/или финансовым учреждением и Пользователем и ни в какой мере не несет ответственности за неправильное или неправомочное использование возможностей Платежной системы Пользователем, а также за злоупотребление Пользователем функциональностью Платежной системы. Взаимные права и обязанности Пользователя и Платежной системы и/или финансового учреждения регулируются соответствующими договорами.
            </li>
            <li>
              Банковские платежи обрабатываются Сервисом в течение 24 часов, при необходимости Сервис может потребовать Верификацию карты(счета) клиента;
            </li>
            <li>
              Если присланная Пользователем сумма криптовалюты (Bitcoin, Ethereum и другими) меньше 0,001 в эквиваленте, деньги назад не возвращаются;
            </li>
          </AgreeSubUl>
        </li>
      </TextItem>
    </FaqContent>
  </ClipBorder>
)

export default Agreement;
