import { useState } from "react";

const useRequest = (apiFn) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const clearError = () => setError(undefined);

  const call = async (...params) => {
    clearError();
    try {
      setLoading(true);
      const response = await apiFn(...params);

      if (response.status === 'fail') {
        throw new Error(response.errorMessage || 'Fail');
      }

      return response;
    } catch (err) {
      const errData = await err.response.json()
      const errorMessage = errData.errorMessage
      if (errorMessage) {
        setError(errData.errorMessage);
      } else {
        setError(err);
      }
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    call,
    error,
    loading,
    clearError,
  };
};

export {
  useRequest
};
